import React, { useEffect, useState } from 'react';
import { createConsumer } from '@rails/actioncable';
import axios from 'axios';
import NotificationAlert from './NotificationAlert';
import { useSelector } from 'react-redux';

const NotificationsHandler = ({ setNotifyCount, setShouldRefreshRequests }) => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);
  const [currentCount, setCurrentCount] = useState(0);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isNotificationVisible, setNotificationVisible] = useState(false);

  const fetchNotificationsCount = async () => {
    if (!user || !token) return;

    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sentinvites/${user.id}`,
        config
      );

      const count = response.data.pending_users.length;
      setCurrentCount(count);
      setNotifyCount(count); // Ensure this updates the parent state
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  useEffect(() => {
    let subscription;

    if (user && token) {
      fetchNotificationsCount(); // Initial fetch

      const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${token}`);

      subscription = cable.subscriptions.create(
        { channel: 'InviteChannel', auth_token: token, user_id: user.id },
        {
          connected: () => {
            // console.log('Connected to InviteChannel');
          },
          received: (data) => {
            // console.log('Received notification:', data); // Debug log

            if (data.notification.includes("invite from user")) {
              if (data.user && data.user.online) {
                const newCount = currentCount + 1;
                // console.log('Updating count to:', newCount); // Debug log
                setCurrentCount(newCount);
                setNotifyCount(newCount);
                setShouldRefreshRequests(true);
              }
            } else if (data.notification.includes("accepted your friend request")) {
              if (data.accepter) {
                setNotificationMessage(data.notification);
                setNotificationVisible(true);
              }
            }
          }
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [user, token]);

  // Update count when currentCount changes
  useEffect(() => {
    setNotifyCount(currentCount);
  }, [currentCount, setNotifyCount]);

  return (
    <NotificationAlert
      message={notificationMessage}
      visible={isNotificationVisible}
      onClose={() => setNotificationVisible(false)}
    />
  );
};

export default NotificationsHandler;
