// redux/thunks/plexThunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { startPlexLinking, plexLinkSuccess, plexLinkFailure, setUser } from '../slices/userSlice';

export const processPlexLink = createAsyncThunk(
  'user/processPlexLink',
  async (pinId, { dispatch, getState }) => {
    try {
      const clientId = localStorage.getItem('plexClientId');
      const state = getState();
      const token = state.token.value;  // Get token from tokenSlice

      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/link_plex`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          pin_id: pinId,
          client_id: clientId
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to link Plex account');
      }

      // Update user data in Redux store
      if (data.user) {
        dispatch(setUser(data.user));
      }

      dispatch(plexLinkSuccess(data));
      return data;
    } catch (error) {
      dispatch(plexLinkFailure(error.message));
      throw error;
    }
  }
);
