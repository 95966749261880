import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faFacebookF, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import PlexDirectMediaLink from './PlexDirectMediaLink';
import { useSelector } from 'react-redux';
import axios from 'axios';

import '../assets/ContentDetail.css';
import imdbLogo from '../assets/images/imdb_logo.png';
import metaCriticLogo from '../assets/images/metacritic-logo-original.png';
import rottenLogo from '../assets/images/Rotten_tomato_logo.png';
import justwatch_logo from '../assets/images/JustWatch-logo.webp';

const ContentDetail = ({ content, contentId, closeDetail, friend }) => {
  const [contentDetails, setContentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.token.value);
  const user = useSelector((state) => state.user.userData);
  const locationPreference = user?.location_preference?.toLowerCase();

  const formatDuration = (durationInMillis) => {
    const minutes = Math.floor(durationInMillis / 60000);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
      return `${minutes} min`;
    } else {
      return `${hours} hr ${remainingMinutes} min`;
    }
  };

  const getProviderLogo = (provider) => {
    if (provider.service && provider.service.imageSet) {
      // Prioritize darkThemeImage, then lightThemeImage, then whiteImage
      return provider.service.imageSet.whiteImage ||
            provider.service.imageSet.lightThemeImage ||
            provider.service.imageSet.darkThemeImage;
    }
    return null; // Return null if no image is available
  };

  useEffect(() => {
    const fetchContentDetails = async () => {
      if (content && content.content_id) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/contents/${content.content_id}`,
            {
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            }
          );
          setContentDetails(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      } else if (content && content.plex_item_details) {
        setContentDetails(content.plex_item_details);
        setLoading(false);
      } else if (contentId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/contents/${contentId}`,
            {
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            }
          );
          setContentDetails(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchContentDetails();
  }, [content, contentId, token, locationPreference]);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : contentDetails ? (
        <div className="contentDetail-container" data-testid='contentDetail-container'>
          <div className="contentDetail-card">
            <div className="close-container">
              <FontAwesomeIcon
                onClick={closeDetail}
                title="Close"
                icon={faSquareXmark}
                size="3x"
              />
            </div>
            <div className="social-media-container">
              {contentDetails.facebook_id && contentDetails.facebook_id !== 'https://www.facebook.com/' && (
                <FontAwesomeIcon
                  icon={faFacebookF}
                  title="Facebook"
                  onClick={() => window.open(contentDetails.facebook_id, '_blank')}
                  className="social-icon"
                />
              )}
              {contentDetails.instagram_id && contentDetails.instagram_id !== 'https://www.instagram.com/' && (
                <FontAwesomeIcon
                  icon={faInstagram}
                  title="Instagram"
                  onClick={() => window.open(contentDetails.instagram_id, '_blank')}
                  className="social-icon"
                />
              )}
              {contentDetails.twitter_id && contentDetails.twitter_id !== 'https://twitter.com/' && (
                <FontAwesomeIcon
                  icon={faXTwitter}
                  title="X"
                  onClick={() => window.open(contentDetails.twitter_id, '_blank')}
                  className="social-icon"
                />
              )}
            </div>
            <h2>{contentDetails.name || contentDetails.title}</h2>
            <div className="media-runtime-container">
              <h5>{contentDetails.type || contentDetails.media_type === 'tv' ? 'TV Show' : 'Movie'}</h5>
              {contentDetails.runtime && (
                <p>{contentDetails.runtime} mins</p>
              )}
            </div>
            <img
              className="ContentDetailPoster"
              src={
                content?.plex_item_details
                  ? content.thumbnail_data
                  : contentDetails.poster_path || contentDetails.thumbnail_data || null
              }
              alt={contentDetails.name || contentDetails.title}
            />
            <p>{contentDetails.overview || contentDetails.summary}</p>
            {contentDetails.Collection && (
              <p>
                <strong>Collection:</strong> {contentDetails.Collection.tag}
              </p>
            )}
            {contentDetails.Genre && (
              <p>
                <strong>Genre:</strong> {contentDetails.Genre.tag}
              </p>
            )}
            {contentDetails.Role && (
              <p>
                <strong>Actor:</strong> {contentDetails.Role.tag}
              </p>
            )}
            {contentDetails.audienceRating && (
              <p>
                <strong>Audience Rating:</strong> {contentDetails.audienceRating}
              </p>
            )}
            {contentDetails.duration && (
              <p>
                <strong>Duration:</strong> {formatDuration(contentDetails.duration)}
              </p>
            )}
            {contentDetails.rating && (
              <p>
                <strong>Rating:</strong> {contentDetails.rating}
              </p>
            )}
            {contentDetails.year && (
              <p>
                <strong>Year:</strong> {contentDetails.year}
              </p>
            )}
            {contentDetails.ratingKey && (
              <PlexDirectMediaLink ratingKey={contentDetails.ratingKey} token={token} />
            )}
            {contentDetails.ratings && contentDetails.ratings.length > 0 && (
              <div className="providers-section">
                <ul>
                  {contentDetails.ratings.map((rating, index) => (
                    <li key={index}>
                      {rating.Source === 'Internet Movie Database' ? (
                        <img src={imdbLogo} alt="IMDb logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : rating.Source === 'Metacritic' ? (
                        <img src={metaCriticLogo} alt="Metacritic logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : rating.Source === 'Rotten Tomatoes' ? (
                        <img src={rottenLogo} alt="Rotten Tomatoes logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : (
                        rating.Source
                      )}{rating.Value}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {contentDetails.director && contentDetails.director !== 'N/A' && (
              <p>
                <strong>Director:</strong> {contentDetails.director}
              </p>
            )}
            {contentDetails.awards && contentDetails.awards !== 'N/A' && contentDetails.awards !== 0 && (
              <p>
                <strong>Awards:</strong> {contentDetails.awards}
              </p>
            )}
            {typeof contentDetails.revenue === 'number' && contentDetails.revenue !== 0 && (
              <p>
                <strong>Total Revenue:</strong> ${contentDetails.revenue}
              </p>
            )}
            {contentDetails.streaming_info && contentDetails.streaming_info.rapid_api_providers && contentDetails.streaming_info.rapid_api_providers.length > 0 && (
              <div className="providers-section">
                <h3>Direct Watch Now Links</h3>
                <div className="provider-container">
                  {contentDetails.streaming_info.rapid_api_providers.map((provider, index) => (
                    <div key={index} className="provider-item">
                      <a href={provider.link} target="_blank" rel="noopener noreferrer">
                        <div className="provider-logo-container" style={{ backgroundColor: provider.service.themeColorCode || '#f0f0f0' }}>
                          <img
                            src={getProviderLogo(provider)}
                            className="provider-logo rapidapi-logo"
                            alt={`${provider.service.name} logo`}
                          />
                        </div>
                      </a>
                      <div className="type-info">
                        <span className="type-label">
                          {provider.type.charAt(0).toUpperCase() + provider.type.slice(1)}
                        </span>
                        {(provider.type === 'rent' || provider.type === 'buy') && provider.price && (
                          <span className="price-info">
                            {provider.price.formatted}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {contentDetails.streaming_info && contentDetails.streaming_info.tmdb_providers &&
             Object.values(contentDetails.streaming_info.tmdb_providers).some(providers => providers && providers.length > 0) && (
              <div className="providers-section">
                <h3>More Provider Info</h3>
                {['buy', 'rent', 'flatrate'].map(providerType => (
                  contentDetails.streaming_info.tmdb_providers[providerType] &&
                  contentDetails.streaming_info.tmdb_providers[providerType].length > 0 && (
                    <div key={providerType}>
                      <h4>{providerType === 'flatrate' ? 'Free with Subscription' : `Where to ${providerType.charAt(0).toUpperCase() + providerType.slice(1)}`}</h4>
                      <div className="provider-container">
                        {contentDetails.streaming_info.tmdb_providers[providerType].map((provider, index) => (
                          <div key={index} className="provider-item">
                            <img
                              src={`https://image.tmdb.org/t/p/original${provider.logo_path}`}
                              alt={`${provider.provider_name} Logo`}
                              className="provider-logo"
                            />
                            <div className="type-info">
                              <span className="type-label">{provider.provider_name}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                ))}
              </div>
            )}

            <div className="contentDetail-credits-container">
              <p>Provider Info sourced from JustWatch</p>
              <img className="justwatch-logo" src={justwatch_logo} alt="JustWatch Logo" />
            </div>
          </div>
        </div>
      ) : (
        <div>Content not found</div>
      )}
    </>
  );
};

export default ContentDetail;
