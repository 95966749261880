import { createSlice } from '@reduxjs/toolkit';
import { setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd } from './subscriptionSlice';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loggedIn: !!sessionStorage.getItem('token'),
  },
  reducers: {
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    clearAuthState: (state) => {
      state.loggedIn = false;
    },
  },
});

export const { setLoggedIn, clearAuthState } = authSlice.actions;

export const initializeAuth = (userData) => (dispatch) => {
  dispatch(setLoggedIn(true));

  // Initialize subscription data if available
  if (userData) {
    dispatch(setSubscriptionStatus(userData.subscription_status || null));
    dispatch(setSubscriptionEndDate(userData.subscription_end_date || null));
    dispatch(setCancelAtPeriodEnd(userData.cancel_at_period_end || false));
  }
};

export default authSlice.reducer;
