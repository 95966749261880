// redux/slices/settingsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    personalInfo: {
      loading: false,
      error: null,
      success: null
    },
    security: {
      loading: false,
      error: null,
      success: null,
      is2FAEnabled: false,
      qrCodeSVG: null,
      showQrCode: true,
      show2FACodeInput: false,
      verificationSuccessful: false,
      totpSecret: '',
    },
    plex: {
      loading: false,
      error: null,
      success: null,
      plexAddress: '',
      plexPort: ''
    }
  },
  reducers: {
    setPersonalInfoLoading: (state, action) => {
      state.personalInfo.loading = action.payload;
    },
    setPersonalInfoError: (state, action) => {
      state.personalInfo.error = action.payload;
      state.personalInfo.success = null;
    },
    setPersonalInfoSuccess: (state, action) => {
      state.personalInfo.success = action.payload;
      state.personalInfo.error = null;
    },
    setSecurityLoading: (state, action) => {
      state.security.loading = action.payload;
    },
    setSecurityError: (state, action) => {
      state.security.error = action.payload;
      state.security.success = null;
    },
    setSecuritySuccess: (state, action) => {
      state.security.success = action.payload;
      state.security.error = null;
    },
    setPlexLoading: (state, action) => {
      state.plex.loading = action.payload;
    },
    setPlexError: (state, action) => {
      state.plex.error = action.payload;
      state.plex.success = null;
    },
    setPlexSuccess: (state, action) => {
      state.plex.success = action.payload;
      state.plex.error = null;
    },
    setPlexDetails: (state, action) => {
      const { plexAddress, plexPort } = action.payload;
      state.plex.plexAddress = plexAddress;
      state.plex.plexPort = plexPort;
    },
    set2FAEnabled: (state, action) => {
      state.security.is2FAEnabled = action.payload;
    },
    setQRCode: (state, action) => {
      state.security.qrCodeSVG = action.payload;
    },
    setShowQRCode: (state, action) => {
      state.security.showQrCode = action.payload;
    },
    setShow2FAInput: (state, action) => {
      state.security.show2FACodeInput = action.payload;
    },
    setVerificationSuccessful: (state, action) => {
      state.security.verificationSuccessful = action.payload;
    },
    setTOTPSecret: (state, action) => {
      state.security.totpSecret = action.payload;
    },
    resetSecurityState: (state) => {
      state.security = {
        ...state.security,
        qrCodeSVG: null,
        showQrCode: true,
        show2FACodeInput: false,
        verificationSuccessful: false,
        totpSecret: '',
      };
    }
  }
});

export const {
  setPersonalInfoLoading,
  setPersonalInfoError,
  setPersonalInfoSuccess,
  setSecurityLoading,
  setSecurityError,
  setSecuritySuccess,
  set2FAEnabled,
  setPlexLoading,
  setPlexError,
  setPlexSuccess,
  setPlexDetails,
  setQRCode,
  setShowQRCode,
  setShow2FAInput,
  setVerificationSuccessful,
  setTOTPSecret,
  resetSecurityState,
} = settingsSlice.actions;

export default settingsSlice.reducer;
