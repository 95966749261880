import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedGenres: [],
  selectedProviders: []
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setSelectedGenres: (state, action) => {
      state.selectedGenres = action.payload;
    },
    setSelectedProviders: (state, action) => {
      state.selectedProviders = action.payload;
    }
  }
});

export const { setSelectedGenres, setSelectedProviders } = filterSlice.actions;

export default filterSlice.reducer;
