import React from 'react';

import '../../assets/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>

      <p>Last updated: Monday 13th January 2025</p>

      <p>Welcome to Flickswipe. We respect your privacy and are committed to protecting it. This Privacy Policy explains our practices regarding the collection, use, disclosure, and protection of information that is collected through our app, as well as your choices regarding the collection and use of information.</p>

      <h2>1. How We Collect and Use Information</h2>

      <h3>a) Personal Information</h3>
      <p>We collect and use your Personal Information for the primary legal basis of facilitating the Service, specifically to enable matching of movies and TV shows with your friends, and to manage your subscription if you choose to subscribe to our premium features. This is achieved when you register with us through the Service, providing personally identifiable information ("Personal Information") such as your name, email address, profile photo, and payment information (for premium subscribers).</p>

      <h3>b) Plex Integration</h3>
      <p>When you connect your Plex account to Flickswipe, we collect your Plex user ID, Plex Auth Token, Plex Access Token, Plex Address, Plex Port, Plex Machine Identifier and the item IDs of items you like or dislike. This information is used solely for the purpose of matching your preferences with those of your friends. We do not collect or store any other data from your Plex account. All Plex-related credentials are encrypted using industry-standard encryption methods.</p>

      <h3>c) Usage Data</h3>
      <p>When you use the App, we collect information about your interactions, including:</p>
      <ul>
        <li>Items you swipe, like, dislike, and save to your personal collection</li>
        <li>Watch history for personalization purposes</li>
        <li>Real-time connection status for friend matching</li>
        <li>Content rating preferences and filters</li>
        <li>Streaming service preferences</li>
      </ul>
      <p>This information is used to improve your user experience, provide personalised recommendations, and generate matches with your friends.</p>

      <h3>d) Technical Data</h3>
      <p>We collect and temporarily store technical data including:</p>
      <ul>
        <li>WebSocket connection data for real-time features</li>
        <li>Session information for app functionality</li>
        <li>Cache data for performance optimization</li>
        <li>Browser fingerprints for security purposes</li>
        <li>Location preferences for content filtering</li>
      </ul>

      <h2>2. Data Storage and Caching</h2>
      <p>We implement various caching mechanisms to improve service performance:</p>
      <ul>
        <li>Temporary caching of user preferences (expiring within 24 hours)</li>
        <li>Short-term storage of Plex library data (refreshed regularly)</li>
        <li>Session data caching for active users</li>
        <li>Content matching data cached for performance</li>
      </ul>
      <p>All cached data is secured and automatically expires after defined periods.</p>

      <h2>3. OAuth and Third-Party Authentication</h2>

      <h3>a) Social Media Login</h3>
      <p>We offer the ability to register and login to the Service using your third-party social media account details (like your Facebook or Google logins). We receive certain profile information from your social media provider, which is used to streamline the registration process and facilitate matching preferences with your friends. We do not post on your behalf or share your information without your permission.</p>

      <h3>b) Two-Factor Authentication</h3>
      <p>When enabled, we securely store TOTP (Time-based One-Time Password) secrets for additional account security. These secrets are encrypted and cannot be accessed in their original form.</p>

      <h2>4. Payment Information and Subscription Management</h2>
      <p>If you choose to subscribe to Flickswipe's premium services, you will be required to provide payment information. Your payment information is processed by our third-party payment processor, Stripe. We store only:</p>
      <ul>
        <li>Stripe customer ID</li>
        <li>Subscription status</li>
        <li>Subscription end date</li>
      </ul>
      <p>We do not store your actual payment information on our servers. Stripe may collect certain financial information from you, such as your credit/debit card number or bank account information, in order to process payments. We encourage you to read Stripe's privacy policy to understand how they handle your financial information.</p>

      <h2>5. Real-Time Features and Communication</h2>
      <p>We use WebSocket connections to provide real-time features:</p>
      <ul>
        <li>Online status tracking</li>
        <li>Instant match notifications</li>
        <li>Friend activity updates</li>
      </ul>
      <p>Connection data is temporarily stored while you're active and cleared upon disconnection.</p>

      <h2>6. Background Processing</h2>
      <p>We perform regular background operations including:</p>
      <ul>
        <li>Removal of unconfirmed accounts (weekly)</li>
        <li>Content preference updates (every 48 hours)</li>
        <li>Match processing</li>
        <li>Cache cleanup</li>
      </ul>
      <p>These processes handle your data according to our retention policies and security standards.</p>

      <h2>7. Data Retention</h2>
      <p>Your Personal Information is retained for as long as your account is active. Specific retention policies:</p>
      <ul>
        <li>Unconfirmed accounts: removed after 7 days</li>
        <li>Content preferences: updated every 48 hours</li>
        <li>Session data: cleared after 24 hours of inactivity</li>
        <li>Cache data: varies by type, maximum 7 days</li>
        <li>Match history: retained until account deletion</li>
      </ul>

      <h2>8. Location-Based Services</h2>
      <p>We use your location preference to:</p>
      <ul>
        <li>Filter available streaming services</li>
        <li>Show relevant content for your region</li>
        <li>Apply appropriate pricing</li>
        <li>Determine content availability</li>
      </ul>
      <p>This information is stored as a country code and used solely for service personalization.</p>

      <h2>9. Your Rights (GDPR Compliance)</h2>
      <p>Under the General Data Protection Regulation (GDPR), you have the following rights regarding your personal data:</p>
      <ul>
        <li>The right to access and receive a copy of your personal data</li>
        <li>The right to rectify inaccurate personal data</li>
        <li>The right to erasure ("right to be forgotten") of your personal data</li>
        <li>The right to restrict processing of your personal data</li>
        <li>The right to data portability</li>
        <li>The right to object to the processing of your personal data</li>
        <li>The right to lodge a complaint with a supervisory authority</li>
      </ul>
      <p>To exercise any of these rights, please contact us at contact@flickswipe.org. We will respond to your request within one month, as required by the GDPR.</p>

      <h2>10. Complaints</h2>
      <p>If you have a concern about our handling of your Personal Information, you can lodge a complaint with the UK Information Commissioner's Office (ICO). More information can be found on the ICO's website at <a href="https://ico.org.uk/make-a-complaint/">https://ico.org.uk/make-a-complaint/</a>.</p>

      <h2>11. Data Transfers and Storage</h2>
      <p>Your data is stored securely on an OVHcloud server in the UK. At present, we do not transfer your data outside the European Economic Area.</p>
      <p>We employ industry-standard encryption techniques to protect sensitive user information. Specifically, we encrypt Plex-related data such as Plex auth tokens, access tokens, and machine identifiers to ensure the security of your Plex account information.</p>

      <h2>12. Security Measures</h2>
      <p>We implement comprehensive security measures including:</p>
      <ul>
        <li>End-to-end encryption of sensitive data</li>
        <li>Rate limiting on API requests</li>
        <li>Secure WebSocket connections</li>
        <li>Token-based authentication</li>
        <li>Regular security audits</li>
        <li>Encrypted data storage</li>
        <li>Automatic session timeout</li>
        <li>IP-based request filtering</li>
      </ul>

      <h2>13. Automated Decision Making</h2>
      <p>Our Service employs automated decision-making for the purpose of matching your content preferences with those of your friends. This process is automatic and is based upon comparing your liked content with your friends' content. If both match, this information gets served to you.</p>

      <h2>14. Changes to Our Privacy Policy</h2>
      <p>Flickswipe may, in its sole discretion, modify or update this Privacy Policy from time to time. When we change the policy in a material manner, we will update the 'last modified' date at the top of this page and notify users through the application.</p>

      <h2>15. How to Contact Us</h2>
      <p>If you have any questions about this Privacy Policy or the Service, please contact us at contact@flickswipe.org.</p>
    </div>
  )
}

export default PrivacyPolicy;
