import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import '../../assets/AwaitConfirmEmail.css';

function AwaitConfirmEmail() {
  const location = useLocation();
  const navigate = useNavigate();
  const message = location.state?.message;
  const type = location.state?.type || 'info';

  if (!message) return null;

  return (
    <div className={`notification notification-${type}`}>
      <p>{message}</p>
      <button
        onClick={() => navigate(location.pathname, { replace: true, state: {} })}
        className="notification-close"
      >
        ×
      </button>
    </div>
  );
}

export default AwaitConfirmEmail;
