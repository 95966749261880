import React, { useState, useCallback, useEffect, useRef } from "react";
import { animated, to } from "react-spring";
import "../../assets/Card.css";
import HeartButton from '../Card Components/HeartButton';
import placeholderImage from '../../assets/images/flickswipe_logo.png';
import { useSelector } from "react-redux";
import axios from 'axios';

const Card = React.memo(({ i, x, y, bind, handleDragEnd }) => {
  const user = useSelector((state) => state.user.userData) || {};
  const data = useSelector((state) => state.deck.data) || [];
  const token = useSelector((state) => state.token.value);
  const item = data[data.length - 1 - i] || {};
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  // Request cache using useRef to persist between renders
  const requestCache = useRef(new Map());

  const fetchThumbnail = useCallback(async (thumbPath) => {
    const cacheKey = `${thumbPath}`;

    // Check if there's already a pending request
    if (requestCache.current.has(cacheKey)) {
      return requestCache.current.get(cacheKey);
    }

    // Create new request promise
    const requestPromise = axios.get(
      `${process.env.REACT_APP_API_URL}/plex_media/thumbnail?thumb_path=${encodeURIComponent(thumbPath)}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        responseType: 'blob'
      }
    )
    .then(response => {
      const url = URL.createObjectURL(response.data);
      // Remove from cache after request completes
      requestCache.current.delete(cacheKey);
      return url;
    })
    .catch(error => {
      // Remove from cache if request fails
      requestCache.current.delete(cacheKey);
      console.error('Error fetching thumbnail:', error);
      return placeholderImage;
    });

    // Store the promise in cache
    requestCache.current.set(cacheKey, requestPromise);
    return requestPromise;
  }, [token]); // token is the only dependency needed

  useEffect(() => {
    let mounted = true;
    const objectUrls = new Set(); // Track created object URLs for cleanup

    const loadImage = async () => {
      let imageURL = placeholderImage;

      try {
        if (item.movie) {
          imageURL = item.movie.poster_path || placeholderImage;
        } else if (item.tv_show) {
          imageURL = item.tv_show.poster_path || placeholderImage;
        } else if (item.thumb) {
          imageURL = await fetchThumbnail(item.thumb);
          if (imageURL !== placeholderImage) {
            objectUrls.add(imageURL); // Track created object URL
          }
        }

        if (mounted) {
          setThumbnailUrl(imageURL);
        }
      } catch (error) {
        console.error('Error loading image:', error);
        if (mounted) {
          setThumbnailUrl(placeholderImage);
        }
      }
    };

    loadImage();

    // Cleanup function
    return () => {
      mounted = false;
      // Revoke all object URLs created during this effect
      objectUrls.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error revoking object URL:', error);
        }
      });
      // Clear any entries in the request cache related to this component
      requestCache.current.clear();
    };
  }, [item, fetchThumbnail]); // Dependencies are now properly tracked

  return (
    <animated.div
      className="animated"
      {...bind(i, item.id, item.ratingKey)}
      onDragEnd={handleDragEnd}
      style={{
        transform: to([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`)
      }}
    >
      <div className="card" data-testid={`card-${item.id || 'placeholder'}`}>
        <img
          src={thumbnailUrl || placeholderImage}
          alt="poster_picture"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = placeholderImage;
          }}
        />
        {token && (item.id || item.ratingKey) && (
          <HeartButton
            token={token}
            contentId={item.id || item.ratingKey}
            user={user}
            source={item.ratingKey ? 'plex' : 'db'}
          />
        )}
      </div>
    </animated.div>
  );
});

export default Card;
