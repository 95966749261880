// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* assets/StripeMinimal.css */
.minimalSub-btn {
  background: linear-gradient(135deg, #ff5e5e, #ff9966);
  color: white;
  border-radius: 30px;
  padding: 10px 20px;
  box-shadow: rgba(40, 40, 40, 0.35) 0px 5px 15px;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  transition: all 0.3s ease;
  text-align: center;
  width: 85vw;
  margin-top: 16px;
  margin-bottom: 16px;
}

.minimalSub-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.minimalSub-btn:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: rgba(40, 40, 40, 0.5) 0px 7px 20px;
}

.stripe-minimal {
  overflow: auto;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.success-message {
  color: #2ecc71;
  padding: 1rem;
  margin: 1rem 0;
}

.error-message {
  color: #e74c3c;
  padding: 1rem;
  margin: 1rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/StripeSalesPitch.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,qDAAqD;EACrD,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,+CAA+C;EAC/C,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,8CAA8C;AAChD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,cAAc;AAChB","sourcesContent":["/* assets/StripeMinimal.css */\n.minimalSub-btn {\n  background: linear-gradient(135deg, #ff5e5e, #ff9966);\n  color: white;\n  border-radius: 30px;\n  padding: 10px 20px;\n  box-shadow: rgba(40, 40, 40, 0.35) 0px 5px 15px;\n  border: none;\n  cursor: pointer;\n  font-size: 1.5em;\n  font-weight: bold;\n  transition: all 0.3s ease;\n  text-align: center;\n  width: 85vw;\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.minimalSub-btn:disabled {\n  opacity: 0.7;\n  cursor: not-allowed;\n}\n\n.minimalSub-btn:hover:not(:disabled) {\n  transform: translateY(-2px);\n  box-shadow: rgba(40, 40, 40, 0.5) 0px 7px 20px;\n}\n\n.stripe-minimal {\n  overflow: auto;\n}\n\n.loader {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 1rem 0;\n}\n\n.success-message {\n  color: #2ecc71;\n  padding: 1rem;\n  margin: 1rem 0;\n}\n\n.error-message {\n  color: #e74c3c;\n  padding: 1rem;\n  margin: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
