// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PlexLoginComponent.css or your relevant CSS file */
.btn-unlink-plex {
  display: block;
  width: 100%;
  max-width: 400px; /* Match your other input/button widths */
  margin: 1rem auto;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background-color: #dc3545;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
}

.btn-unlink-plex:hover {
  background-color: #c82333;
}

.btn-unlink-plex:active {
  transform: scale(0.98);
}

/* Media queries for mobile responsiveness */
@media screen and (max-width: 768px) {
  .btn-unlink-plex {
    max-width: 100%; /* Full width on mobile */
    margin: 0.75rem auto;
    padding: 0.875rem;
    font-size: 0.95rem; /* Slightly smaller font on mobile */
  }
}

@media screen and (max-width: 480px) {
  .btn-unlink-plex {
    padding: 0.75rem;
    margin: 0.5rem auto;
  }
}

/* Add touch device optimization */
@media (hover: none) {
  .btn-unlink-plex {
    -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
  }

  .btn-unlink-plex:active {
    background-color: #c82333; /* Show pressed state on touch devices */
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/UnLinkPlexAccount.css"],"names":[],"mappings":"AAAA,qDAAqD;AACrD;EACE,cAAc;EACd,WAAW;EACX,gBAAgB,EAAE,yCAAyC;EAC3D,iBAAiB;EACjB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,2DAA2D;AAC7D;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA,4CAA4C;AAC5C;EACE;IACE,eAAe,EAAE,yBAAyB;IAC1C,oBAAoB;IACpB,iBAAiB;IACjB,kBAAkB,EAAE,oCAAoC;EAC1D;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,mBAAmB;EACrB;AACF;;AAEA,kCAAkC;AAClC;EACE;IACE,wCAAwC,EAAE,mCAAmC;EAC/E;;EAEA;IACE,yBAAyB,EAAE,wCAAwC;EACrE;AACF","sourcesContent":["/* PlexLoginComponent.css or your relevant CSS file */\n.btn-unlink-plex {\n  display: block;\n  width: 100%;\n  max-width: 400px; /* Match your other input/button widths */\n  margin: 1rem auto;\n  padding: 0.75rem 1rem;\n  font-size: 1rem;\n  font-weight: 500;\n  color: white;\n  background-color: #dc3545;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.2s ease, transform 0.1s ease;\n}\n\n.btn-unlink-plex:hover {\n  background-color: #c82333;\n}\n\n.btn-unlink-plex:active {\n  transform: scale(0.98);\n}\n\n/* Media queries for mobile responsiveness */\n@media screen and (max-width: 768px) {\n  .btn-unlink-plex {\n    max-width: 100%; /* Full width on mobile */\n    margin: 0.75rem auto;\n    padding: 0.875rem;\n    font-size: 0.95rem; /* Slightly smaller font on mobile */\n  }\n}\n\n@media screen and (max-width: 480px) {\n  .btn-unlink-plex {\n    padding: 0.75rem;\n    margin: 0.5rem auto;\n  }\n}\n\n/* Add touch device optimization */\n@media (hover: none) {\n  .btn-unlink-plex {\n    -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */\n  }\n\n  .btn-unlink-plex:active {\n    background-color: #c82333; /* Show pressed state on touch devices */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
