import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import LinkPlexAccount from '../Auth/LinkPlexAccount';
import UnLinkPlexAccount from '../Auth/UnLinkPlexAccount';
import {
  setPlexLoading,
  setPlexError,
  setPlexSuccess,
  setPlexDetails
} from '../../redux/slices/settingsSlice';
import { setUser } from '../../redux/slices/userSlice';
import { processPlexLink } from '../../redux/thunks/plexThunks';

import '../../assets/plexSection.css'

const PlexSection = ({ user, token }) => {
  const dispatch = useDispatch();
  const { loading, error, success, plexAddress, plexPort } = useSelector(state => state.settings.plex);
  const plexLinkingStatus = useSelector(state => state.user.plexLinkingStatus);
  const plexLinkingError = useSelector(state => state.user.plexLinkingError);

  // Local state for form values
  const [localAddress, setLocalAddress] = useState(user.plex_address || '');
  const [localPort, setLocalPort] = useState(user.plex_port || '');
  const [showWarning, setShowWarning] = useState(false);

  const handleAddressChange = (e) => {
    setLocalAddress(e.target.value);
    setShowWarning(true);
  };

  const handlePortChange = (e) => {
    setLocalPort(e.target.value);
    setShowWarning(true);
  };

  const handleSubmitPlexSettings = async (e) => {
    e.preventDefault();
    dispatch(setPlexLoading(true));

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/update_plex_settings`,
        {
          plex_address: localAddress,
          plex_port: localPort || null
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === 'success') {
        // Update the Redux user state with the new Plex settings
        dispatch(setUser({
          ...user,
          plex_address: response.data.plex_address,
          plex_port: response.data.plex_port
        }));

        dispatch(setPlexSuccess('Plex settings updated successfully'));
        dispatch(setPlexDetails({
          plexAddress: localAddress,
          plexPort: localPort
        }));
        setShowWarning(false);
      }
    } catch (error) {
      dispatch(setPlexError(error.response?.data?.message || 'Failed to update Plex settings'));
    } finally {
      dispatch(setPlexLoading(false));
    }
  };

  const handleLinkSuccess = async (pinId) => {
    try {
      await dispatch(processPlexLink(pinId)).unwrap();
      dispatch(setPlexSuccess("Plex account linked successfully"));
    } catch (error) {
      dispatch(setPlexError(error.message));
    }
  };

  const handleUnlinkSuccess = async (message) => {
    dispatch(setPlexSuccess(message));
    dispatch(setPlexDetails({ plexAddress: '', plexPort: '' }));
    setLocalAddress('');
    setLocalPort('');
    setShowWarning(false);
  };

  const handleError = (errorMessage) => {
    dispatch(setPlexError(errorMessage));
  };

  if (plexLinkingStatus === 'linking') {
    return (
      <div className="section-content">
        <div className="loading-message">Linking your Plex account...</div>
      </div>
    );
  }

  return (
    <div className="section-content">
      {(error || plexLinkingError) && (
        <div className="error-message">{error || plexLinkingError}</div>
      )}
      {success && <div className="success-message">{success}</div>}

      {user.plex_id ? (
        <>
          <div className="plex-warning">
            <p className="text-warning">
              ⚠️ Warning: Modifying these settings should only be done if you're using a proxy
              or external access method. Incorrect settings may break your Plex connection.
              If issues occur, you'll need to unlink and relink your account.
            </p>
          </div>

          <form onSubmit={handleSubmitPlexSettings} className="plex-settings-form">
            <input
              type="text"
              value={localAddress}
              onChange={handleAddressChange}
              placeholder="Plex Address"
              className="plex-input"
            />
            <input
              type="text"
              value={localPort}
              onChange={handlePortChange}
              placeholder="Plex Port (Optional)"
              className="plex-input"
            />

            {showWarning && (
              <button
                type="submit"
                className="btn-update-plex-settings"
                disabled={loading}
              >
                {loading ? 'Updating...' : 'Update Plex Settings'}
              </button>
            )}

            <UnLinkPlexAccount
              onUnlinkSuccess={handleUnlinkSuccess}
              onError={handleError}
              setPlexAddress={() => setLocalAddress('')}
              setPlexPort={() => setLocalPort('')}
            />
          </form>
        </>
      ) : (
        <LinkPlexAccount
          onLinkSuccess={handleLinkSuccess}
          onError={handleError}
        />
      )}
    </div>
  );
};

export default PlexSection;
