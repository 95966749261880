// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add to your CSS */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1000;
}

.notification-info {
  background: #e3f2fd;
  border-left: 4px solid #2196f3;
}

.notification-error {
  background: #ffebee;
  border-left: 4px solid #f44336;
}

.notification-close {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.5;
}

.notification-close:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/assets/AwaitConfirmEmail.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,qCAAqC;EACrC,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":["/* Add to your CSS */\n.notification {\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  padding: 15px 20px;\n  border-radius: 4px;\n  background: #fff;\n  box-shadow: 0 2px 5px rgba(0,0,0,0.2);\n  z-index: 1000;\n}\n\n.notification-info {\n  background: #e3f2fd;\n  border-left: 4px solid #2196f3;\n}\n\n.notification-error {\n  background: #ffebee;\n  border-left: 4px solid #f44336;\n}\n\n.notification-close {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  background: none;\n  border: none;\n  font-size: 18px;\n  cursor: pointer;\n  opacity: 0.5;\n}\n\n.notification-close:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
