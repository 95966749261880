// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oauth-password-notice {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: var(--text-primary);
}
`, "",{"version":3,"sources":["webpack://./src/assets/SecuritySection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".oauth-password-notice {\n  display: flex;\n  justify-content: center;\n  font-size: 1.5rem;\n  color: var(--text-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
