import React, { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { ClipLoader } from "react-spinners";
import '../assets/StripeSalesPitch.css';

const StripeSalesPitch = ({
  handleSubmit,
  handleCancelTrial,
  handleUnsubscribe,
  handleRenew,
  stripe,
  loading,
  cardOptions,
  priceInfo,
  loadingMessage,
  successMessage,
  errorMessage,
  subscriptionStatus,
  subscriptionEndDate,
  cancelAtPeriodEnd,
  setShowCardSection,
}) => {

  const [showCardDetails, setShowCardDetails] = useState(false);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const formatPrice = (price, currency) => {
    if (!price || !currency) return 'Loading...';

    try {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency.toUpperCase(),
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(price / 100); // Convert from cents to whole units
    } catch (error) {
      console.error('Error formatting price:', error);
      return `${price / 100} ${currency.toUpperCase()}`;
    }
  };

  const renderPrice = () => {
    if (!priceInfo) return 'Loading...';
    return formatPrice(priceInfo.price, priceInfo.currency);
  };

  // StripeSalesPitch.js
const renderSubscriptionDetails = () => {
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const renderPrice = () => {
    return priceInfo ? formatPrice(priceInfo.price, priceInfo.currency) : 'Loading...';
  };

  // Helper to determine if subscription is completely ended
  const isSubscriptionEnded = () => {
    if (!subscriptionEndDate) return true;
    return new Date(subscriptionEndDate) < new Date();
  };

  // TRIAL SCENARIOS
  if (subscriptionStatus === 'trialing') {
    if (cancelAtPeriodEnd) {
        return (
          <div className="subscription-details">
            <p>Your trial has been canceled and will end on <strong>{formatDate(subscriptionEndDate)}</strong>.</p>
            <p>You still have access to premium features until then.</p>
            <button className="minimalSub-btn" onClick={handleRenew}>
              Continue with Subscription
            </button>
          </div>
        );
      }
      return (
        <div className="subscription-details">
          <p>You are currently in your free trial period, which ends on <strong>{formatDate(subscriptionEndDate)}</strong>.</p>
          <p>After your trial, you'll be billed monthly at {priceInfo ? formatPrice(priceInfo.price, priceInfo.currency) : 'Loading...'}.</p>
          <button className="minimalSub-btn" onClick={handleCancelTrial}>
            Cancel Trial
          </button>
        </div>
      );
    }

    // ACTIVE SUBSCRIPTION SCENARIOS
    if (subscriptionStatus === 'active') {
      if (cancelAtPeriodEnd) {
        return (
          <div className="subscription-details">
            <p>Your subscription has been canceled but you can continue using premium features until <strong>{formatDate(subscriptionEndDate)}</strong>.</p>
            <p>You won't be billed after this date.</p>
            <button className="minimalSub-btn" onClick={handleRenew}>
              Reactivate Subscription
            </button>
          </div>
        );
      }
      return (
        <div className="subscription-details">
          <p>Your subscription is active and will renew on <strong>{formatDate(subscriptionEndDate)}</strong>.</p>
          <p>You are being billed {priceInfo ? formatPrice(priceInfo.price, priceInfo.currency) : 'Loading...'} monthly.</p>
          <button className="minimalSub-btn" onClick={handleUnsubscribe}>
            Cancel Subscription
          </button>
        </div>
      );
    }

    // PAST DUE OR INCOMPLETE SCENARIOS
    if (subscriptionStatus === 'past_due' || subscriptionStatus === 'incomplete') {
      return (
        <div className="subscription-details error">
          <p>There was an issue with your last payment.</p>
          <p>Please update your payment method to continue your subscription.</p>
          <button className="minimalSub-btn" onClick={() => setShowCardSection(true)}>
            Update Payment Method
          </button>
        </div>
      );
    }

    // CANCELED SCENARIOS
    if (subscriptionStatus === 'canceled') {
      if (isSubscriptionEnded()) {
        return (
          <div className="subscription-details">
            <p>Your subscription has ended. Resubscribe to regain access to premium features.</p>
            <button className="minimalSub-btn" onClick={() => setShowCardSection(true)}>
              Subscribe Again
            </button>
          </div>
        );
      }
      return (
        <div className="subscription-details">
          <p>Your subscription is canceled but active until <strong>{formatDate(subscriptionEndDate)}</strong>.</p>
          <p>You can resubscribe anytime to maintain uninterrupted access.</p>
          <button className="minimalSub-btn" onClick={() => setShowCardSection(true)}>
            Resubscribe Now
          </button>
        </div>
      );
    }

    // UNPAID OR REQUIRES ACTION SCENARIOS
    if (subscriptionStatus === 'unpaid' || subscriptionStatus === 'incomplete_expired') {
      return (
        <div className="subscription-details error">
          <p>Your subscription has been paused due to a payment issue.</p>
          <p>Update your payment method to restore access.</p>
          <button className="minimalSub-btn" onClick={() => setShowCardSection(true)}>
            Update Payment Details
          </button>
        </div>
      );
    }

    // DEFAULT/UNKNOWN STATUS
    return (
      <div className="subscription-details">
        <p>Start your 7-day free trial to access premium features.</p>
        <button className="minimalSub-btn" onClick={() => setShowCardSection(true)}>
          Start Free Trial
        </button>
      </div>
    );
  };

  // Show subscription details if user has an active subscription or trial
  if (subscriptionStatus === 'trialing' || subscriptionStatus === 'active') {

    return (
      <div className='stripe-minimal'>
        {renderSubscriptionDetails()}
        {loading && (
          <div className="loader">
            <ClipLoader size={35} color="#ff7c62" />
            <p>{loadingMessage}</p>
          </div>
        )}
        {successMessage && (
          <div className="success-message">
            <p>{successMessage}</p>
          </div>
        )}
        {errorMessage && (
          <div className="error-message">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='stripe-minimal'>
      <h2>Unlock the Full Power of Our Platform with Premium Subscription</h2>
      <p>Start your 7-day free trial today and gain access to exciting premium features. You'll get unlimited undo swipes, personalized content tailored just for you, priority customer support, and much more!</p>
      <p>No commitment, cancel anytime before your trial ends.</p>
      {!showCardDetails ? (
        <button
          className="minimalSub-btn"
          onClick={() => setShowCardDetails(true)}
        >
          Start My 7 Day Free Trial
        </button>
      ) : (
        <form data-testid="cardform" onSubmit={handleSubmit}>
          <CardElement options={cardOptions} />
          <p>Your subscription will automatically renew at the end of the trial period, and you'll be billed monthly at a rate of {renderPrice()}.</p>
          <p>Remember, you're always in control. You can cancel your subscription anytime from your account settings.</p>
          <button
            className="minimalSub-btn"
            type="submit"
            disabled={!stripe || loading}
          >
            Start My Premium Experience
          </button>
        </form>
      )}
      {loading && (
        <div className="loader">
          <ClipLoader
            size={35}
            color="#ff7c62"
          />
          <p>{loadingMessage}</p>
        </div>
      )}
      {successMessage && (
        <div className="success-message">
          <p>{successMessage}</p>
        </div>
      )}
      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default StripeSalesPitch;
