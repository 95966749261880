import React, { useEffect, useRef, useState } from 'react';
import { createConsumer } from '@rails/actioncable';
import ContentDetail from '../ContentDetail';
import Confetti from 'react-confetti';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';
import { useSelector } from 'react-redux';

import '../../assets/MatchNotifications.css';

function MatchNotifications() {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const [matched, setMatched] = useState(false);
  const [data, setData] = useState({});
  const [friend, setFriend] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedContentId, setSelectedContentId] = useState(null);
  const [showContentDetail, setShowContentDetail] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const nodeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cableRef = useRef(null);
  const subscriptionRef = useRef(null);

  useEffect(() => {
    if (loggedIn && user) {
      cableRef.current = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${token}`);
      subscriptionRef.current = cableRef.current.subscriptions.create(
        { channel: 'MatchChannel', user_id: user.id },
        {
          received: async (receivedData) => {
            setData(receivedData.payload);
            setMatched(true);

            const config = {
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
              }
            };

            try {
              const currentUserResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/${receivedData.payload.user_id}`, config);
              const friendResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/${receivedData.payload.friend_id}`, config);
              setCurrentUser(currentUserResponse.data.user);
              setFriend(friendResponse.data.user);

              if (receivedData.payload.content_source === "plex") {

                let thumbnailUrl = null;
                if (receivedData.payload.thumbnail_data) {
                  // Create a data URL for the thumbnail image
                  const thumbnailData = receivedData.payload.thumbnail_data;
                  thumbnailUrl = `data:image/jpeg;base64,${thumbnailData}`;
                }

                setContent({
                  ...receivedData.payload.plex_item_details,
                  poster_path: thumbnailUrl
                });
              } else {
                const contentResponse = await axios.get(`${process.env.REACT_APP_API_URL}/contents/${receivedData.payload.content_id}`, config);
                setContent(contentResponse.data);
              }
              setLoading(false);
            } catch (error) {
              console.error(error);
              setLoading(false);
            }
          },
        }
      );
    }

    return () => {
      subscriptionRef.current?.unsubscribe();
      cableRef.current?.disconnect();
    };
  }, [user, token, loggedIn]);

  const handleWatchNow = async (event) => {
    event.preventDefault();
    if (data.content_source === "plex") {
      const ratingKey = data.plex_item_details.ratingKey;
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/plex_media/prepare_redirect_to_plex/${ratingKey}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          window.location.href = data.redirectUrl; // Perform the redirect
        } else {
          console.error('Failed to get redirect URL');
        }
      } catch (error) {
        console.error('Error fetching redirect URL:', error);
      }
    } else {
      setShowContentDetail(true);
    }
  };

  const handleNoThanks = () => {
    setMatched(false);
    setData({});
    setContent({});
  };

  return (
    <>
      <CSSTransition
        nodeRef={nodeRef}
        in={matched}
        timeout={300}
        classNames="matched-popup"
        unmountOnExit
      >
        <div ref={nodeRef} className="popup">
          <div className="popup-content">
            <h2>You've matched!</h2>
            <h3>{content.title || content.name}</h3>
            {content.poster_path && (
              <img
                className="popup-poster"
                src={content.poster_path}
                alt={content.title || content.name}
              />
            )}
            {user && (
              <p className="matched-friend">
                {user.id === currentUser.id
                  ? `with ${friend.firstname}`
                  : `with ${currentUser.firstname}`}
              </p>
            )}
            <div className="matched-button-container">
              <button className="watch-now" onClick={handleWatchNow}>
                <FontAwesomeIcon icon={faThumbsUp} /> Watch Now
              </button>
              <button className="no-thanks" onClick={handleNoThanks}>
                <FontAwesomeIcon icon={faThumbsDown} /> No Thanks
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>

      {matched && (
        <>
          <div className="matched-modal-overlay" onClick={handleNoThanks} />
          <div className="confetti-container">
            <Confetti
              width={windowSize.width}
              height={windowSize.height}
              recycle={false}
              numberOfPieces={200}
            />
          </div>
        </>
      )}

      {showContentDetail && (
        <ContentDetail
          contentId={data.content_id}
          closeDetail={() => setShowContentDetail(false)}
        />
      )}
    </>
  );
}

export default MatchNotifications;
