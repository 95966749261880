import React, { useEffect, useState, CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faUndo } from '@fortawesome/free-solid-svg-icons'

import { useSprings, useSpring } from "@react-spring/web";
import { useGesture } from "react-with-gesture";
import { createConsumer } from "@rails/actioncable";
import { useSelector, useDispatch } from 'react-redux';

import { setIsFetching, setIsFilterLoading, setData, setCurrentIndex, incrementCurrentIndex, decrementCurrentIndex } from '../../redux/slices/deckSlice';

import MatchNotifications from "./MatchNotifications";
import StripeSubscription from "../registrations/StripeSubscription";
import Card from "./Card";
import Modal from "./modal";
import DeckFiltering from "./DeckFiltering";
import LikeIndicator from './LikeIndicator';
import DislikeIndicator from './DislikeIndicator';
import useDeckFetch from '../../Hooks/useDeckFetch';
import useMoreCards from '../../Hooks/useMoreCards';
import useDiscoverCards from '../../Hooks/useDiscoverCards';
import useFetchingText from '../../Hooks/useFetchingText';
import useSwipeActions from '../../Hooks/useSwipeActions';
import useSwipeIndicators from '../../Hooks/useSwipeIndicators';

import "../../assets/Deck.css";

import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const limit = 15

const to = i => ({
  x: 0,
  y: 0,
  scale: 1,
  rot: 0,
  delay: 0,
});
const from = i => ({ rot: 0, scale: 1.5, y: -100 });

const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${r /
    10}deg) rotateZ(${r}deg) scale(${s})`;
    function Deck() {
      // Redux States usage //
      const dispatch = useDispatch();

      const data = useSelector((state) => state.deck.data);
      const currentIndex = useSelector((state) => state.deck.currentIndex);
      const user = useSelector((state) => state.user.userData);
      const token = useSelector((state) => state.token.value);
      const isFetching = useSelector((state) => state.deck.isFetching);
      const isFilterLoading = useSelector((state) => state.deck.isFilterLoading);
      const subscriptionStatus = useSelector((state) => state.subscription.status);

    // Component state //
    const [nope] = useState(() => new Set());
    const [activateModal, setActivateModal] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [detailRequest, setDetailRequest] = useState(false);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(1);
    // const [expandSearch, setExpandSearch] = useState(false);
    const [currentCard, setCurrentCard] = useState(null)
    const [plexLibraries, setPlexLibraries] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState({});
    const [selectedRatings, setSelectedRatings] = useState([]);
    const [selectedPlexLibraries, setSelectedPlexLibraries] = useState([]);
    const [selectedCollections, setSelectedCollections] = useState([]);

    const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${token}`);

    // Hooks
    const fetchingText = useFetchingText(isFetching);
    const { likeProps, dislikeProps, handleIndicators } = useSwipeIndicators();

    const {
      swipeCounter,
      setSwipeCounter,
      swipedCards,
      setSwipedCards,
      swipeDirection,
      setSwipeDirection,
      swipeContentId,
      setSwipeContentId,
      undoLastSwipe
    } = useSwipeActions(token, data, currentIndex, dispatch, setData, setCurrentIndex);

    const {
      error,
      expandSearch,
      setExpandSearch,
      lastPreference,
      setLastPreference,
    } = useDeckFetch(token, user, selectedGenres, selectedRatings, selectedPlexLibraries, selectedCollections, limit, offset);

    const { handleMoreCards } = useMoreCards(selectedGenres, selectedRatings, selectedPlexLibraries, selectedCollections, limit, offset, expandSearch, setOffset, setLastPreference);

    const { handleDiscoverCards } = useDiscoverCards(
      selectedGenres,
      selectedRatings,
      selectedPlexLibraries,
      selectedCollections,
      limit,
      offset,
      expandSearch,
      setOffset
    );

    const handleGenreChange = (newSelectedGenres) => {
      setSelectedGenres(newSelectedGenres);
    };

    const handleRatingChange = (newRatings) => {
      setSelectedRatings(newRatings);
    };

    const handlePlexLibraryChange = (newPlexLibraries) => {
      setSelectedPlexLibraries(newPlexLibraries);
    };


    const getSelectedGenreIds = (selectedGenres) => {
      return Object.keys(selectedGenres).filter(genreId => selectedGenres[genreId]);
    };

    const handleCollectionChange = (newCollections) => {
      setSelectedCollections(newCollections);
    };

    const resetFilters = () => {
      setSelectedGenres({});
      setSelectedRatings([]);
      setSelectedPlexLibraries([]);
      setSelectedCollections([]);
    };

    const [props, set] = useSprings(data.length, i => ({
      ...to(i),
      from: from(i)
    }), [data.length]);

    // const [props, set] = useSprings(Math.max(0, data.length), i => {
    //   return {
    //     ...to(i),
    //     from: from(i)
    //   };
    // });

    const bind = useGesture(
      ({
        args: [index, id, ratingKey],
        down,
        delta: [xDelta],
        distance,
        direction: [xDir],
        velocity,
        event,
      }) => {
        handleIndicators(down, xDir);

        if (!down) {
          const screenWidth = window.innerWidth;
          const threshold = screenWidth / 3.975;

          // Card has left the visible screen towards the left (dislike)
        if (xDelta < -threshold) {
          setSwipeDirection('dislike');
          if (id) {
            setSwipeContentId(id);
          } else if (ratingKey) {
            setSwipeContentId(ratingKey);
          }
        }
        // Card has left the visible screen towards the right (like)
        else if (xDelta > threshold) {
          setSwipeDirection('like');
          if (id) {
            setSwipeContentId(id);
          } else if (ratingKey) {
            setSwipeContentId(ratingKey);
          }
        }

        if (xDelta < -threshold || xDelta > threshold) {
          let source = 'db'; // default source
          if (ratingKey) {
            source = 'plex';
          }

          setSwipeCounter(prevCount => prevCount + 1);
          setSwipedCards([
            ...swipedCards,
            {
              id: id || ratingKey,
              direction: xDelta > threshold ? 'like' : 'dislike',
              source: source
            }
          ]);
          dispatch(incrementCurrentIndex());
        }

        if (Math.abs(xDelta) > threshold) {
          nope.add(index);
        }
      }

      set(i => {
        if (index !== i) return;

        const isNope = nope.has(index);

        const x = isNope ? (200 + window.innerWidth) * Math.sign(xDelta) : down ? xDelta : 0;

        const rot = xDelta / 100 + (isNope ? Math.sign(xDelta) * 10 * Math.abs(xDelta) / 100 : 0);

        const scale = down ? 1.1 : 1;
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 25, tension: down ? 800 : isNope ? 200 : 500 }
        };
      }, setLoading(false));
    }
  , [token, setSwipeDirection, setSwipeContentId, handleIndicators]);

    const clickHandler = () => {
      setActivateModal(true);
      setDetailRequest(true);
      fetchDetail();
    };

    const fetchDetail = async () => {
      let config = {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };
      try {
        const currentCard = data[currentIndex];
        const source = currentCard.ratingKey ? 'plex' : 'db';
        const cardId = currentCard.ratingKey || currentCard.id;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/contents/${cardId}?source=${source}`, config);
        setDetailRequest(false);
        setShowDetail(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const expandSearchResults = async () => {
      resetFilters(); // Reset the filters before expanding the search
      const selectedGenreIds = Object.keys(selectedGenres).filter(genreId => selectedGenres[genreId]);

      dispatch(setIsFetching(true));
      dispatch(setIsFilterLoading(true));
      setExpandSearch(true);

      let config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const url = `${process.env.REACT_APP_API_URL}/contents?limit=${limit}&offset=${offset}&filters=${selectedGenreIds.join(",")}&expand_search=true${lastPreference ? `&preference=${lastPreference}` : ""}${selectedRatings.length > 0 ? selectedRatings.map(rating => `&rating[]=${rating.source}&rating_value[]=${rating.ratingValue}`).join('') : ""}&selected_libraries=${selectedPlexLibraries.join(",")}&selected_collections=${selectedCollections.join(",")}`;

      try {
        const response = await axios.get(url, config);
        const newData = response.data.contents || [];
        dispatch(setData(newData));
        dispatch(setIsFetching(false));
        dispatch(setIsFilterLoading(false));
        const newIndex = Math.max(0, Math.min(newData.length - 1, newData.length - 1));
        dispatch(setCurrentIndex(newIndex));
      } catch (error) {
        dispatch(setIsFetching(false));
        dispatch(setIsFilterLoading(false));
      }
    };

    useEffect(() => {
      if (currentIndex >= 0 && currentIndex < data.length) {
        setCurrentCard(data[currentIndex]);
      } else {
        setCurrentCard(null);
      }
    }, [data, currentIndex]);

    useEffect(() => {
      const fetchPlexLibraries = async () => {
        // Only proceed if user has Plex access token
        if (!user?.plex_access_token) {
          setPlexLibraries([]); // Set empty array for non-Plex users
          return;
        }

        let config = {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };

        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/plex_media/plex-libraries`, config);
          setPlexLibraries(response.data);
        } catch (error) {
          console.error("Error fetching Plex libraries:", error);
          setPlexLibraries([]); // Ensure we have a fallback empty array on error
        }
      };

      if (token && user) {
        fetchPlexLibraries();
      }
    }, [token, user]);

    const override: CSSProperties = {
      borderColor: "#ff7c62",
      height: "45px",
      width: "40px"
    };

    const cards = props.map(({ x, y, rot, scale }, i) => {
      if (i < 0 || i >= data.length) {
        return null;
      }
      const item = data[i];
      const uniqueKey = item?.id || item?.ratingKey || `card-${i}`;

      return (
        <Card
          key={uniqueKey}
          i={i}
          x={x}
          y={y}
          rot={rot}
          scale={scale}
          trans={trans}
          data={data[i]}
          bind={bind}
        />
      );
    }).filter(Boolean);

  return (
    <div id="deck" data-testid="deck">
      <LikeIndicator style={likeProps} />
      <DislikeIndicator style={dislikeProps} />
      {isFilterLoading ? (
      <div className="filter-loading">
      </div>
    ) : (
      <>
      {data.length > 0 && cards}
      </>
    )}
    {((subscriptionStatus === 'active') || (subscriptionStatus === 'trialing')) && !isFetching && currentIndex >= 0 && currentIndex < data.length && swipeCounter < data.length && (
        <button onClick={undoLastSwipe} data-testid="undobtn" className="undo-button">
          <FontAwesomeIcon icon={faUndo} />
        </button>
      )}
      {!isFetching && currentIndex >= 0 && currentIndex < data.length && swipeCounter < data.length && (
        <button onClick={() => clickHandler()} className="fetchDetail-button" data-testid="fetchdetailsbtn">
          <FontAwesomeIcon icon={faBars} />
        </button>
      )}
      <MatchNotifications cable={cable} />
      {((subscriptionStatus === 'active') || (subscriptionStatus === 'trialing')) && (
        <DeckFiltering
          onGenreChange={handleGenreChange}
          onRatingChange={handleRatingChange}
          plexLibraries={plexLibraries}
          selectedPlexLibraries={selectedPlexLibraries}
          onSelectPlexLibrary={handlePlexLibraryChange}
          selectedCollections={selectedCollections}
          onSelectCollection={handleCollectionChange}
        />
      )}
      <div className="deck-container">
        {isFetching ? (
          <div className="fetchingContainer">
            <ClipLoader
              loading={true}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              cssOverride={override}
            />
            <p className="isFetchingText">{fetchingText}</p>
          </div>
        ) : (
          <div className="optionsContainer" style={{ display: swipeCounter >= data.length ? 'block' : 'none' }}>
          <div className="button-container">
            { data.length !== 0 &&
              <button
                className="load-button"
                onClick={() => { handleDiscoverCards(() => { nope.clear() || set(i => to(i)); setLoading(true); setSwipeCounter(0); }); }}>
                Explore more titles
              </button>
            }
            {((subscriptionStatus === '') || (subscriptionStatus === 'canceled')) && (
              <div className="subscription-pitch-container">
                <StripeSubscription showSalesPitch={true} />
              </div>
            )}
            {data.length === 0 && !expandSearch && !isFetching && (
              <div className="expand-search">
                <p>You've swiped through all the content from your preferred providers before! Click the button below to discover more from other providers.</p>
                <button className="load-button" onClick={() => {
                  expandSearchResults();
                  resetFilters();
                }}>Discover More</button>
              </div>
            )}
            {((subscriptionStatus === 'active') || (subscriptionStatus === 'trialing')) && data.length !== 0 && (
              <>
              <button
                className="load-button"
                onClick={() => {
                  handleMoreCards("more", "more", () => {
                    nope.clear() || set(i => to(i));
                    setLoading(true);
                    setSwipeCounter(0);
                  });
                }}
              >
                <p>Similar to what you like</p>
              </button>
                <button
                  className="load-button"
                  onClick={() => {
                    handleMoreCards("less", "less", () => {
                      nope.clear() || set(i => to(i));
                      setLoading(true);
                      setSwipeCounter(0);
                    });
                  }}
                >
                  <p>Different from what you like</p>
                </button>
              </>
            )}
            </div>
          </div>
        )}
      </div>
      {activateModal ? ( detailRequest === false ? ( <Modal data={showDetail} closeModal={setActivateModal} /> ) : (
        <ClipLoader
          size={40}
          color="#ff7c62"
          className="modalSpinner"
        /> )) : ( "" )}
    </div>
  );
}

export default Deck;
