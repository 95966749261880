import React from 'react';
import '../assets/Checkbox.css';

function CheckboxList({ options, selectedOptions, onChange, isProvider, testId }) {
  // console.log(`CheckboxList render - isProvider: ${isProvider}, options:`, options, "selectedOptions:", selectedOptions);

  const safeSelectedOptions = Array.isArray(selectedOptions) ? selectedOptions : [];

  const handleButtonClick = (event) => {
    const option = isProvider ? event.currentTarget.getAttribute('data-id') : event.target.name;
    // console.log(`Option clicked: ${option}, isProvider: ${isProvider}`);

    let updatedOptions = safeSelectedOptions.slice();
    if (updatedOptions.includes(option)) {
      updatedOptions = updatedOptions.filter((selectedOption) => selectedOption !== option);
    } else {
      updatedOptions.push(option);
    }
    // console.log("Updated options:", updatedOptions);
    onChange(updatedOptions);
  };

  return (
    <div className="checkboxContainer" data-testid={testId}>
      {options.map((option) => {
        const optionId = isProvider ? option.provider_id.toString() : option;
        const isSelected = safeSelectedOptions.includes(optionId);

        return (
          <label
            key={isProvider ? option.provider_id : option}
            className={`checkboxLabel${isProvider ? ' provider' : ''}`}
          >
            {!isProvider && (
              <button
                name={option}
                onClick={handleButtonClick}
                className={`button ${option.toLowerCase()} ${isSelected ? 'buttonSelected' : ''}`}
              >
                {option}
              </button>
            )}
            {isProvider && (
              <div
                className={`providerWrapper ${isSelected ? 'checked' : ''}`}
                onClick={handleButtonClick}
                data-id={option.provider_id.toString()}
              >
                <img
                  src={option.logo_path}
                  alt={`${option.provider_name} logo`}
                  className={`providerImage ${isSelected ? 'providerImagechecked' : ''}`}
                />
              </div>
            )}
          </label>
        );
      })}
    </div>
  );
}

export default CheckboxList;
