import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/CookieNotice.css';

const CookieNotice = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const hasSeenNotice = localStorage.getItem('cookieNoticeAcknowledged');
        if (!hasSeenNotice) {
            setIsVisible(true);
        }
    }, []);

    const acknowledgeCookies = () => {
        localStorage.setItem('cookieNoticeAcknowledged', 'true');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-notice-overlay">
            <div className="cookie-notice">
                <div className="cookie-notice-content">
                    <div className="cookie-notice-text">
                        <h3>Cookie Notice</h3>
                        <p>
                            We use essential cookies to make Flickswipe work. These cookies are necessary for:
                        </p>
                        <ul>
                            <li>Keeping you signed in</li>
                            <li>Processing payments</li>
                            <li>Social sign-in features</li>
                        </ul>
                        <p>
                            This includes cookies from{' '}
                            <a
                                href="https://policies.google.com/technologies/cookies"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Google
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://stripe.com/cookies-policy/legal"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Stripe
                            </a>.
                            {' '}See our{' '}
                            <Link to="/privacy">Privacy Policy</Link>.
                        </p>
                    </div>
                    <button
                        onClick={acknowledgeCookies}
                        className="cookie-notice-button"
                        aria-label="Accept cookies"
                    >
                        Got it
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieNotice;
