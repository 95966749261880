import React, { useEffect } from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { setUser } from '../../redux/slices/userSlice';
import { setToken } from '../../redux/slices/tokenSlice';
import { setLoggedIn } from '../../redux/slices/authSlice';
import { setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd } from '../../redux/slices/subscriptionSlice';

const FacebookLoginButton = ({ setRequire2FA, setUserId2FA, setInvalidLogin }) => {
  const dispatch = useDispatch();

  // Clear Facebook auth state on mount and unmount
  useEffect(() => {
    const clearFacebookState = () => {
      // Clear Facebook auth state
      window.localStorage.removeItem('fblo_' + process.env.REACT_APP_FACEBOOK_ID);

      // Remove any Facebook SDK data
      Object.keys(localStorage).forEach(key => {
        if (key.includes('facebook') || key.includes('fb') || key.includes('FB')) {
          localStorage.removeItem(key);
        }
      });

      Object.keys(sessionStorage).forEach(key => {
        if (key.includes('facebook') || key.includes('fb') || key.includes('FB')) {
          sessionStorage.removeItem(key);
        }
      });

      // Clear Facebook cookies
      document.cookie.split(";").forEach(c => {
        if (c.includes('fb') || c.includes('facebook') || c.includes('FB')) {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        }
      });
    };

    clearFacebookState();
    return () => clearFacebookState();
  }, []);

  const handleFacebookCallback = async (response) => {
    // Clear any existing Facebook state first
    window.localStorage.removeItem('fblo_' + process.env.REACT_APP_FACEBOOK_ID);

    // Check for valid response
    if (!response || !response.email || !response.id || !response.accessToken) {
      setInvalidLogin('Facebook login failed. Please try again.');
      return;
    }

    try {
      const userData = {
        id: response.id,
        email: response.email,
        first_name: response.first_name,
        last_name: response.last_name,
        profile_image_url: response.picture?.data?.url,
        access_token: response.accessToken,
      };

      const apiResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/facebook/callback`,
        { user: userData }
      );

      if (apiResponse.data.require_2fa) {
        setRequire2FA(true);
        setUserId2FA(apiResponse.data.user_id);
      } else {
        dispatch(setUser(apiResponse.data.user));
        dispatch(setToken(apiResponse.data.jwt));
        dispatch(setLoggedIn(true));
        dispatch(setSubscriptionStatus(apiResponse.data.user.subscription_status));
        dispatch(setSubscriptionEndDate(apiResponse.data.user.subscription_end_date));
        dispatch(setCancelAtPeriodEnd(apiResponse.data.user.cancel_at_period_end));

        sessionStorage.setItem('userData', JSON.stringify(apiResponse.data.user));
        sessionStorage.setItem('token', apiResponse.data.jwt);
        sessionStorage.setItem('refresh_token', apiResponse.data.refresh_token);
      }
    } catch (error) {
      console.error('Error during Facebook login:', error);
      setInvalidLogin(error.response?.data?.message || 'Facebook login failed. Please try again.');
    }
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_ID}
      initParams={{
        version: 'v18.0',
        xfbml: true,
        cookie: false,
        status: true,
        auth_type: 'rerequest'
      }}
      scope="email,public_profile"
      onSuccess={(response) => {
        if (!response || response.status === 'unknown') {
          setInvalidLogin('Facebook login failed. Please try again.');
          return;
        }
      }}
      onProfileSuccess={handleFacebookCallback}
      onFail={(error) => {
        setInvalidLogin('Facebook login failed. Please try again.');
        // Clear any failed auth state
        window.localStorage.removeItem('fblo_' + process.env.REACT_APP_FACEBOOK_ID);
      }}
      render={({ onClick }) => (
        <button
          className="custom-fb-button"
          onClick={(e) => {
            e.preventDefault();
            // Clear any existing auth state before attempting login
            window.localStorage.removeItem('fblo_' + process.env.REACT_APP_FACEBOOK_ID);
            onClick();
          }}
          type="button"
          style={{
            backgroundColor: '#1877f2',
            border: 'none',
            borderRadius: '4px',
            color: 'white',
            cursor: 'pointer',
            padding: '10px 20px',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
            justifyContent: 'center',
            marginTop: '10px',
            marginBottom: '10px'
          }}
        >
          <FontAwesomeIcon icon={faFacebookF} style={{ marginRight: '8px' }} />
          Login with Facebook
        </button>
      )}
    />
  );
};

export default FacebookLoginButton;
