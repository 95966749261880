import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import LoginTwoFactorAuth from './LoginTwoFactorAuth';
import "../../assets/Login.css";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '../../redux/slices/tokenSlice';
import { setUser } from '../../redux/slices/userSlice'
import { setSubscriptionEndDate, setSubscriptionStatus, setCancelAtPeriodEnd } from '../../redux/slices/subscriptionSlice';
import { setLoggedIn } from '../../redux/slices/authSlice';

import FacebookLoginComponent from './FacebookLogin';
import PlexLoginComponent from './PlexLoginComponent';

export default function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [invalidLogin, setInvalidLogin] = useState(false)
  const [require2FA, setRequire2FA] = useState(false);
  const [userId2FA, setUserId2FA] = useState(null);
  const [verificationSuccessful, setVerificationSuccessful] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const navigate = useNavigate();

  const handleGoogleLoginSuccessRef = useRef();
  const handleGoogleLoginErrorRef = useRef();

  const handleLogin = (event) => {
    event.preventDefault();

    // Reset error states before each submit
    setEmailError('');
    setPasswordError('');

    if (email.trim() === '') {
      setEmailError('Email cannot be blank');
      return;
    }

    if (password.trim() === '') {
      setPasswordError('Password cannot be blank');
      return;
    }

    event.target.reset();

    const user = { email, password };

    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, { user: { ...user, auth_provider: 'facebook' } }, { withCredentials: true })
      .then((response) => {
        if (response.data.require_2fa) {
          setRequire2FA(true);
          setUserId2FA(response.data.user_id);
        } else {
          dispatch(setToken(response.data.jwt));
          dispatch(setUser(response.data.user));
          dispatch(setLoggedIn(true));
          dispatch(setSubscriptionStatus(response.data.user.subscription_status));
          dispatch(setSubscriptionEndDate(response.data.user.subscription_end_date));
          dispatch(setCancelAtPeriodEnd(response.data.user.cancel_at_period_end));
          sessionStorage.setItem('userData', JSON.stringify(response.data.user));
          sessionStorage.setItem('token', response.data.jwt);
          sessionStorage.setItem('refresh_token', response.data.refresh_token);
        }
      })
      .catch((error) => {
        if (error.response) {
          setInvalidLogin(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  }

  function handleVerification(success, errorMessage) {
    if (success) {
      setRequire2FA(false);
      setVerificationSuccessful(true);
      navigate('/'); // Navigate to the desired route after successful verification
    } else {
      // Handle the error or show a message if the verification failed
      console.log(errorMessage);
    }
  }

  function decodeJwtResponseFromGoogleAPI(token) {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload =
   decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' +
   c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload)
}

  const handleGoogleLoginSuccess = (response) => {
    const tokenId = response.credential
    const responsePayload = decodeJwtResponseFromGoogleAPI(response.credential);

    const userData = {
      email: responsePayload.email,
      first_name: responsePayload.given_name,
      last_name: responsePayload.family_name,
      profile_image_url: responsePayload.picture,
    };

    const api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    });

    api.post('/auth/google/callback', { google_access_token: tokenId, user: userData })
    .then((response) => {
      if (response.data.require_2fa) {
        setRequire2FA(true);
        setUserId2FA(response.data.user_id);
      } else {
        dispatch(setToken(response.data.jwt));
        dispatch(setUser(response.data.user));
        dispatch(setLoggedIn(true));
        dispatch(setSubscriptionStatus(response.data.user.subscription_status));
        dispatch(setSubscriptionEndDate(response.data.user.subscription_end_date));
        dispatch(setCancelAtPeriodEnd(response.data.user.cancel_at_period_end));
        sessionStorage.setItem('userData', JSON.stringify(response.data.user));
        sessionStorage.setItem('token', response.data.jwt);
        sessionStorage.setItem('refresh_token', response.data.refresh_token);
      }
    })
    .catch((error) => {
        console.log(error)
        if (error.response) {
          setInvalidLogin(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  };

  const handleGoogleLoginError = (error) => {
    // Handle the failed Google login.
    console.log('Google login failed', error);
  };

  useEffect(() => {
    if (!window.google) {
      setTimeout(() => {
        if (!window.google) {
          const script = document.createElement("script");
          script.src = "https://accounts.google.com/gsi/client";
          script.async = true;
          script.defer = true;
          script.onload = () => {
            initGoogleButton();
          };
          document.head.appendChild(script);
        } else {
          initGoogleButton();
        }
      }, 100);
      return;
    }

    const initGoogleButton = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
        callback: handleGoogleLoginSuccessRef.current,
      });
      window.google.accounts.id.renderButton(document.querySelector(".g_id_signin"), {
        onsuccess: handleGoogleLoginSuccessRef.current,
        onfailure: handleGoogleLoginErrorRef.current,
      });
    };

    initGoogleButton();
  }, []);

  useEffect(() => {
    // Clear any existing auth tokens and sessions
    sessionStorage.clear(); // Clear all session storage
    localStorage.removeItem('facebook_login_state');

    // Clear any existing auth states in Redux
    dispatch(setToken(null));
    dispatch(setUser(null));
    dispatch(setLoggedIn(false));
    dispatch(setSubscriptionStatus(null));
    dispatch(setSubscriptionEndDate(null));
    dispatch(setCancelAtPeriodEnd(false));

    // Clear cookies that contain 'fb' or 'facebook'
    document.cookie.split(";").forEach((c) => {
      if (c.includes('fb') || c.includes('facebook')) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      }
    });
  }, []);

  handleGoogleLoginSuccessRef.current = handleGoogleLoginSuccess;
  handleGoogleLoginErrorRef.current = handleGoogleLoginError;



  if (require2FA) {
    return (
      <LoginTwoFactorAuth
        verificationSuccessful={verificationSuccessful}
        user_id={userId2FA}
        onVerification={handleVerification}
      />
    );
  }

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2>Login</h2>
        <input
          type="text"
          name="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && <p className="error">{emailError}</p>}
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        {passwordError && <p className="error">{passwordError}</p>}
        <div className="forgot-password-link">
          <Link to="/forgot_password">Forgotten your password?</Link>
        </div>
        <button className="login-submit-btn" onClick={() => (setInvalidLogin(false))} type="submit">Submit</button>
        <div
          className="g_id_signin"
          data-testid="google-signin-button"
          data-client_id={process.env.REACT_APP_GOOGLE_CLIENTID}
          data-onsuccess="handleGoogleLoginSuccess"
          data-onfailure="handleGoogleLoginError"
        />
        <FacebookLoginComponent
          type="button"
          setRequire2FA={setRequire2FA}
          setUserId2FA={setUserId2FA}
          setInvalidLogin={setInvalidLogin}
        />
        <div className="plex-login-container">
          <PlexLoginComponent />
        </div>
        { invalidLogin &&
        <h4 className="login-error">{invalidLogin}</h4> }
        <div className='loginsignup-container'>
          <p className='noaccount'>Dont have an account? </p>
        <Link className='signup-link' to="/signup">Signup now </Link>
      </div>
      </form>
    </div>
  );
}
