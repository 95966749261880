import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startPlexLinking, plexLinkFailure } from '../../redux/slices/userSlice';
import plexLogo from '../../assets/images/plex-logo.png';
import '../../assets/PlexLoginComponent.css';

const PLEX_AUTH_URL = 'https://app.plex.tv/auth#';

function LinkPlexAccount({ onLinkSuccess, onError }) {
  const dispatch = useDispatch();
  const plexLinkingStatus = useSelector((state) => state.user.plexLinkingStatus);
  const plexLinkingError = useSelector((state) => state.user.plexLinkingError);

  useEffect(() => {
    if (!localStorage.getItem('plexClientId')) {
      const uniqueId = 'flickswipe-' + Math.random().toString(36).substr(2, 9);
      localStorage.setItem('plexClientId', uniqueId);
    }
  }, []);

  const handleLinkPlex = async () => {
    try {
      dispatch(startPlexLinking());

      const response = await fetch('https://plex.tv/api/v2/pins?strong=true', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'X-Plex-Client-Identifier': localStorage.getItem('plexClientId'),
          'X-Plex-Product': 'Flickswipe',
          'X-Plex-Version': '1.0.0',
          'X-Plex-Device': 'Web',
          'X-Plex-Device-Name': 'Browser',
          'X-Plex-Platform': 'Web',
          'X-Plex-Platform-Version': '1.0.0',
          'X-Plex-Provides': 'controller',
          'X-Plex-Language': 'en'
        }
      });

      const pinData = await response.json();

      localStorage.setItem('pinId', pinData.id);
      localStorage.setItem('linkingPlex', 'true');

      const params = new URLSearchParams({
        'clientID': localStorage.getItem('plexClientId'),
        'code': pinData.code,
        'context[device][product]': 'Flickswipe',
        'context[device][version]': '1.0.0',
        'context[device][platform]': 'Web',
        'context[device][device]': 'Browser',
        'forwardUrl': `${process.env.REACT_APP_PLEX_REDIRECT_URL}/process-plex-link`
      });

      window.location.href = `${PLEX_AUTH_URL}?${params.toString()}`;
    } catch (error) {
      console.error("Error initiating Plex login:", error);
      dispatch(plexLinkFailure(error.message));
      onError && onError(error.message);
    }
  };

  return (
    <div className="plex-link-container">
      {plexLinkingStatus !== 'linking' && (
        <button
          className="plex-login-btn"
          onClick={handleLinkPlex}
          disabled={plexLinkingStatus === 'linking'}
        >
          <img src={plexLogo} alt="Plex Logo" className="plex-logo" />
          Link Plex Account
        </button>
      )}
      {plexLinkingStatus === 'linking' && (
        <div className="linking-status">
          Linking your Plex account...
        </div>
      )}
      {/* {plexLinkingError && (
        <div className="error-message">
          <p>{plexLinkingError}</p>
        </div>
      )} */}
    </div>
  );
}

export default LinkPlexAccount;
