import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import placeholderImage from '../assets/images/flickswipe_logo.png';
import PlexDirectMediaLink from './PlexDirectMediaLink';
import { useSelector } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

import '../assets/SavedContentDetail.css';
import imdbLogo from '../assets/images/imdb_logo.png';
import metaCriticLogo from '../assets/images/metacritic-logo-original.png';
import rottenLogo from '../assets/images/Rotten_tomato_logo.png';
import justwatch_logo from '../assets/images/JustWatch-logo.webp';
import tmdbLogo from '../assets/images/tmdb_logo_small.svg';
const formatDuration = (durationInMinutes) => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  return hours > 0 ? `${hours} hr ${minutes} min` : `${minutes} min`;
};

const SavedContentDetail = ({ plexThumb, plexDetails, loadingDetails, token, attributes, closeDetail }) => {

  const user = useSelector((state) => state.user.userData);
  const locationPreference = user?.location_preference?.toLowerCase();

  const getProviderLogo = (provider) => {
    if (provider.service && provider.service.imageSet) {
      return provider.service.imageSet.darkThemeImage ||
             provider.service.imageSet.lightThemeImage ||
             provider.service.imageSet.whiteImage;
    }
    return null;
  };

  const streamingInfo = attributes.source !== 'plex' ? (attributes.savedContentStreamingInfo || {}) : {};
  const isPlexContent = attributes.source === 'plex';

  return (
    <>
      {loadingDetails ? (
        <ClipLoader
          loading={loadingDetails}
          size={50}
          color="#ff7c62"
        />
      ) : attributes ? (
        <div className="SavedContentDetail-container">
          <div className="SavedContentDetail-card">
            <div className="SavedClose-container">
              <FontAwesomeIcon
                onClick={closeDetail}
                icon={faSquareXmark}
                size="3x"
              />
            </div>
            <div className="SavedSocial-media-container">
              {attributes.facebook_id && attributes.facebook_id !== 'https://www.facebook.com/' && (
                <FontAwesomeIcon
                  icon={faFacebookF}
                  onClick={() => window.open(attributes.facebook_id, '_blank')}
                  className="SavedSocial-icon"
                  data-testid="facebookicon"
                />
              )}
              {attributes.instagram_id && attributes.instagram_id !== 'https://www.instagram.com/' && (
                <FontAwesomeIcon
                  icon={faInstagram}
                  onClick={() => window.open(attributes.instagram_id, '_blank')}
                  className="SavedSocial-icon"
                  data-testid="instagramicon"
                />
              )}
              {attributes.twitter_id && attributes.twitter_id !== 'https://twitter.com/' && (
                <FontAwesomeIcon
                  icon={faXTwitter}
                  onClick={() => window.open(attributes.twitter_id, '_blank')}
                  className="SavedSocial-icon"
                  data-testid="xicon"
                />
              )}
            </div>
            <h2>{isPlexContent ? plexDetails.title : (attributes.name || attributes.title)}</h2>
            <div className="SavedMedia-runtime-container">
              {isPlexContent ? (
                <h5>{plexDetails.librarySectionTitle}</h5>
              ) : (
                <h5>{attributes.media_type === 'tv' ? 'TV Show' : 'Movie'}</h5>
              )}

              {attributes.runtime ?
                formatDuration(attributes.runtime) && (
                  <p>{formatDuration(attributes.runtime)}</p>
                )
                : null
              }
            </div>
            <img className="SavedContentDetailPoster" src={plexThumb || attributes.poster_path || placeholderImage} alt={attributes.name || attributes.title} />
            <p>{attributes.overview || plexDetails?.summary}</p>
            <div className="SavedProviders-section">
              <ul>
                {attributes.ratings && attributes.ratings.map((rating, index) => (
                  <li key={index}>
                    {rating.Source === 'Internet Movie Database' ? (
                      <img src={imdbLogo} alt="IMDb logo" style={{ height: '3em', marginRight: '0.5em' }} />
                    ) : rating.Source === 'Metacritic' ? (
                      <img src={metaCriticLogo} alt="Metacritic logo" style={{ height: '3em', marginRight: '0.5em' }} />
                    ) : rating.Source === 'Rotten Tomatoes' ? (
                      <img src={rottenLogo} alt="Rotten Tomatoes logo" style={{ height: '3em', marginRight: '0.5em' }} />
                    ) : (
                      rating.Source
                    )}
                    {rating.Value}
                  </li>
                ))}
              </ul>
            </div>

            {((attributes.director && attributes.director !== 'N/A') || plexDetails?.Director?.tag) && (
              <p>
                <strong>Director:</strong> {plexDetails?.Director?.tag || attributes.director}
              </p>
            )}
            {plexDetails?.Producer?.tag && (
              <p>
                <strong>Producer:</strong> {plexDetails.Producer.tag || attributes.producer}
              </p>
            )}
            {plexDetails?.Writer?.tag && (
              <p>
                <strong>Writer:</strong> {plexDetails.Writer.tag || attributes.writer}
              </p>
            )}
            {(plexDetails?.studio) && (
              <p>
                <strong>Studio:</strong> {plexDetails.studio}
              </p>
            )}
            {attributes.awards && attributes.awards !== 'N/A' && attributes.awards !== 0 && (
              <p>
                <strong>Awards:</strong> {attributes.awards}
              </p>
            )}
            {typeof attributes.revenue === 'number' && attributes.revenue !== 0 && (
              <p>
                <strong>Total Revenue:</strong> ${attributes.revenue.toLocaleString()}
              </p>
            )}

            {!isPlexContent && (
              <>
                {streamingInfo.rapid_api_providers && streamingInfo.rapid_api_providers.length > 0 && (
                  <div className="SavedProviders-section">
                    <h3>Direct Watch Now Links</h3>
                    <div className="SavedProvider-container">
                      {streamingInfo.rapid_api_providers.map((provider, index) => (
                        <div key={index} className="SavedProvider-item">
                          <a href={provider.link} target="_blank" rel="noopener noreferrer">
                            <div className="SavedProvider-logo-container" style={{ backgroundColor: provider.service.themeColorCode || '#f0f0f0' }}>
                              <img
                                src={getProviderLogo(provider)}
                                className="SavedProvider-logo SavedRapidapi-logo"
                                alt={`${provider.service.name} logo`}
                              />
                            </div>
                          </a>
                          <div className="SavedType-info">
                            <span className="SavedType-label">
                              {provider.type.charAt(0).toUpperCase() + provider.type.slice(1)}
                            </span>
                            {(provider.type === 'rent' || provider.type === 'buy') && provider.price && (
                              <span className="SavedPrice-info">
                                {provider.price.formatted}
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {streamingInfo.tmdb_providers && Object.keys(streamingInfo.tmdb_providers).length > 0 && (
                  <div className="SavedProviders-section">
                    <h3>More Provider Info</h3>
                    {['buy', 'rent', 'flatrate'].map(providerType => (
                      streamingInfo.tmdb_providers[providerType] &&
                      streamingInfo.tmdb_providers[providerType].length > 0 && (
                        <div key={providerType}>
                          <h4>{providerType === 'flatrate' ? 'Free with Subscription' : `Where to ${providerType.charAt(0).toUpperCase() + providerType.slice(1)}`}</h4>
                          <div className="SavedProvider-container">
                            {streamingInfo.tmdb_providers[providerType].map((provider, index) => (
                              <div key={index} className="SavedProvider-item">
                                <img
                                  src={`https://image.tmdb.org/t/p/original${provider.logo_path}`}
                                  alt={`${provider.provider_name} Logo`}
                                  className="SavedProvider-logo"
                                />
                                <div className="SavedType-info">
                                  <span className="SavedType-label">{provider.provider_name}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                )}

                <div className="savedContentDetail-credits-container">
                  <p>Provider Info sourced from JustWatch</p>
                  <img className="justwatch-logo" src={justwatch_logo} alt="JustWatch Logo" />
                </div>
              </>
            )}

            {isPlexContent && (
              <PlexDirectMediaLink ratingKey={attributes.rating_key} token={token} />
            )}

          </div>
        </div>
      ) : (
        <div>Content not found</div>
      )}
    </>
  );
};

export default SavedContentDetail;
