import { createSlice } from '@reduxjs/toolkit';

// Helper function to safely parse JSON from storage
const safeJSONParse = (item) => {
  try {
    return item ? JSON.parse(item) : null;
  } catch (e) {
    console.error('Error parsing JSON:', e);
    return null;
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: safeJSONParse(sessionStorage.getItem('userData')),
    plexLinkingStatus: 'idle', // 'idle' | 'linking' | 'success' | 'error'
    plexLinkingError: null,
    plexAuthData: safeJSONParse(sessionStorage.getItem('plexAuthData')),
  },
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload;
      if (action.payload) {
        sessionStorage.setItem('userData', JSON.stringify(action.payload));
      } else {
        sessionStorage.removeItem('userData');
      }
    },
    clearUser: (state) => {
      state.userData = null;
      state.plexLinkingStatus = 'idle';
      state.plexLinkingError = null;
      state.plexAuthData = null;
      sessionStorage.removeItem('userData');
      sessionStorage.removeItem('plexAuthData');
    },
    startPlexLinking: (state) => {
      state.plexLinkingStatus = 'linking';
      state.plexLinkingError = null;
    },
    plexLinkSuccess: (state, action) => {
      state.plexLinkingStatus = 'success';
      state.plexAuthData = action.payload;
      if (action.payload) {
        sessionStorage.setItem('plexAuthData', JSON.stringify(action.payload));
      }
    },
    plexLinkFailure: (state, action) => {
      state.plexLinkingStatus = 'error';
      state.plexLinkingError = action.payload;
    },
    resetPlexLinking: (state) => {
      state.plexLinkingStatus = 'idle';
      state.plexLinkingError = null;
    }
  },
});

export const {
  setUser,
  clearUser,
  startPlexLinking,
  plexLinkSuccess,
  plexLinkFailure,
  resetPlexLinking
} = userSlice.actions;

export default userSlice.reducer;
