import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { processPlexLink } from '../../redux/thunks/plexThunks';

const ProcessPlexLink = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handlePlexCallback = async () => {
      const pinId = localStorage.getItem('pinId');
      if (!pinId) {
        console.error('No pin ID found');
        navigate('/update', { state: { openPlexSection: true } });
        return;
      }

      try {
        await dispatch(processPlexLink(pinId)).unwrap();
        localStorage.removeItem('pinId');
        localStorage.removeItem('linkingPlex');
        navigate('/update', { state: { openPlexSection: true } });
      } catch (error) {
        console.error('Error processing Plex link:', error);
        navigate('/update', { state: { openPlexSection: true } });
      }
    };

    handlePlexCallback();
  }, [dispatch, navigate]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h2 className="text-xl font-semibold mb-4">Processing Plex Authentication...</h2>
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
      </div>
    </div>
  );
};

export default ProcessPlexLink;
