// components/Update.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setUser } from '../../redux/slices/userSlice';
import ImageUpload from './ImageUpload';
import UserPreference from '../UserPreference';
import StripeSubscription from './StripeSubscription';
import PersonalInfoSection from '../../components/settings/PersonalInfoSection';
import SecuritySection from '../../components/settings/SecuritySection';
import PlexSection from '../../components/settings/PlexSection';
import tmdb_logo from '../../assets/images/tmdb_logo.svg';
import '../../assets/update.css';
import axios from 'axios';

function Update({ logOut }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Redux selectors
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const subscriptionStatus = useSelector((state) => state.subscription.status);
  const subscriptionEndDate = useSelector((state) => state.subscription.endDate);
  const cancelAtPeriodEnd = useSelector((state) => state.subscription.cancelAtPeriodEnd);

  // Local state for UI
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(true);
  const [isSecurityOpen, setIsSecurityOpen] = useState(false);
  const [isPlexOpen, setIsPlexOpen] = useState(false);

  useEffect(() => {
    if (!loggedIn && !sessionStorage.getItem('token')) {
      navigate('/');
    }
  }, [loggedIn, navigate]);

  useEffect(() => {
    if (location.state?.message) {
      if (location.state.showPlexSettings) {
        setIsPlexOpen(true);
      }
      navigate('.', { replace: true, state: {} });
    } else if (location.state?.openPlexSection) {
      setIsPlexOpen(true);
      navigate('.', { replace: true, state: {} });
    }
  }, [location, navigate]);

  const saveUserPreferences = async (selectedProviders) => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_preferences`,
        {
          user_preference: {
            streaming_providers: selectedProviders.map((provider) => ({
              provider_name: provider.provider_name,
              logo_path: provider.logo_path,
            })),
          },
        },
        config
      );

      if (response.data.status === "success") {
        // Handle success if needed
      } else {
        console.error("Failed to save user preferences:", response.data.errors);
      }
    } catch (error) {
      console.error("Error saving user preferences:", error);
    }
  };

  const deleteAccount = async () => {
    if (window.confirm("Are you sure you want to leave us?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/users/${user.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200) {
          localStorage.token = '';
          logOut();
          window.location = "/";
        }
      } catch (error) {
        console.error("Error deleting account:", error);
        alert("Error deleting account. Please try again.");
      }
    }
  };

  const toggleSection = (section) => {
    switch (section) {
      case 'personalInfo':
        setIsPersonalInfoOpen(!isPersonalInfoOpen);
        break;
      case 'security':
        setIsSecurityOpen(!isSecurityOpen);
        break;
      case 'plex':
        setIsPlexOpen(!isPlexOpen);
        break;
      default:
        break;
    }
  };

  if (!user && !sessionStorage.getItem('userData')) {
    return (
      <div className="update-container">
        <div className="update-items">
          <p>Please log in to access this page.</p>
        </div>
      </div>
    );
  }
console.log(user)
  return (
    <div className="update-container">
      {user ? (
        <>
          <div className="update-items">
            <h2>Update {user.firstname}'s Information</h2>
            <ImageUpload user={user} token={token} />
            <p>Current email: {user.email}</p>
            <p>Current firstname: {user.firstname}</p>
            <p>Current lastname: {user.lastname}</p>
          </div>

          <div className="settings-sections">
            <div className="section-header" onClick={() => toggleSection('personalInfo')}>
              <h3>Personal Information</h3>
              <span>{isPersonalInfoOpen ? '▲' : '▼'}</span>
            </div>
            {isPersonalInfoOpen && (
              <PersonalInfoSection user={user} token={token} />
            )}

            <div className="section-header" onClick={() => toggleSection('security')}>
              <h3>Security Settings</h3>
              <span>{isSecurityOpen ? '▲' : '▼'}</span>
            </div>
            {isSecurityOpen && (
              <SecuritySection user={user} token={token} />
            )}

            <div className="section-header" onClick={() => toggleSection('plex')}>
              <h3>{!user.plex_id ? 'Link Plex Account' : 'Plex Settings'}</h3>
              <span>{isPlexOpen ? '▲' : '▼'}</span>
            </div>
            {isPlexOpen && (
              <PlexSection user={user} token={token} />
            )}
          </div>

          <UserPreference
            user={user}
            token={token}
            saveUserPreferences={saveUserPreferences}
          />

          <StripeSubscription
            token={token}
            cancelAtPeriodEnd={cancelAtPeriodEnd}
            subscriptionStatus={subscriptionStatus}
            subscriptionEndDate={subscriptionEndDate}
            showSalesPitch={true}
          />

          <div className="delete-container">
            <button onClick={() => setDropdownOpen(!dropdownOpen)}>
              Terms &amp; Policies
            </button>
            {dropdownOpen && (
              <div className="terms-dropdown-content">
                <Link className="update-terms-link" to="/privacy">
                  Privacy Policy
                </Link>
                &nbsp;
                <Link className="update-terms-link" to="/terms">
                  Terms of Use
                </Link>
              </div>
            )}
            <button className="btn-delete-account" onClick={deleteAccount}>
              DELETE ACCOUNT
            </button>
          </div>

          <div className="credits-container">
            <p>This product uses the TMDb API but is not endorsed or certified by TMDb.</p>
            <img src={tmdb_logo} alt="The Movie Database Logo" />
          </div>
        </>
      ) : (
        <div className="update-items">
          <p>Loading user information...</p>
        </div>
      )}
    </div>
  );
}

export default Update;
