// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plex-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  padding: 0.75rem;
  margin: 0.5rem auto; /* Center the button horizontally */
  background-color: #e5a00d;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.plex-login-btn:hover {
  background-color: #e5a10d1d;
}

.plex-logo {
  height: 24px;
  margin-right: 0.5rem;
}

.plex-link-container {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plex-link-container .error-message {
  color: #ff0000;
  margin-top: 10px;
  text-align: center;
}

.plex-link-container .plex-login-btn {
  /* Reuse existing plex-login-btn styles */
}
`, "",{"version":3,"sources":["webpack://./src/assets/PlexLoginComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB,EAAE,mCAAmC;EACxD,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,sBAAsB;EACtB,0CAA0C;EAC1C,2CAA2C;EAC3C,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".plex-login-btn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  max-width: 400px;\n  padding: 0.75rem;\n  margin: 0.5rem auto; /* Center the button horizontally */\n  background-color: #e5a00d;\n  color: #ffffff;\n  border: none;\n  border-radius: 0.25rem;\n  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);\n  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.plex-login-btn:hover {\n  background-color: #e5a10d1d;\n}\n\n.plex-logo {\n  height: 24px;\n  margin-right: 0.5rem;\n}\n\n.plex-link-container {\n  margin: 15px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.plex-link-container .error-message {\n  color: #ff0000;\n  margin-top: 10px;\n  text-align: center;\n}\n\n.plex-link-container .plex-login-btn {\n  /* Reuse existing plex-login-btn styles */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
