import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../redux/slices/userSlice';
import axios from 'axios';

import '../../assets/UnLinkPlexAccount.css';

const UnLinkPlexAccount = ({ onUnlinkSuccess, onError, setPlexAddress, setPlexPort }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.value);

  const handleUnlink = async () => {
    if (window.confirm('Are you sure you want to unlink your Plex account? This will remove all your Plex-related data.')) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/auth/unlink_plex`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.status === 'success') {
          dispatch(setUser(response.data.user));
          // Clear plex-related state
          setPlexAddress('');
          setPlexPort('');
          onUnlinkSuccess?.('Plex account unlinked successfully');
        }
      } catch (error) {
        onError?.(error.response?.data?.message || 'Failed to unlink Plex account');
      }
    }
  };

  return (
    <button
      className="btn-unlink-plex"
      onClick={handleUnlink}
    >
      Unlink Plex Account
    </button>
  );
};

export default UnLinkPlexAccount;
