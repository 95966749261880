// components/StripeSubscription.js
import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { ClipLoader } from "react-spinners";
import '../../assets/StripeSubscription.css';
import StripeSalesPitch from '../StripeSalesPitch';  // Updated import
import { useDispatch, useSelector } from 'react-redux';
import { setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd } from '../../redux/slices/subscriptionSlice';
import { setUser } from '../../redux/slices/userSlice';

function StripeSubscription({ showSalesPitch }) {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const token = useSelector((state) => state.token.value);
  const subscriptionStatus = useSelector((state) => state.subscription.status);
  const subscriptionEndDate = useSelector((state) => state.subscription.endDate);
  const cancelAtPeriodEnd = useSelector((state) => state.subscription.cancelAtPeriodEnd);

  const [showCardSection, setShowCardSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Processing payment...");
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [priceInfo, setPriceInfo] = useState(null);
  const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState(subscriptionStatus);

  useEffect(() => {
    if (token) {
      fetchPriceInfo();
    }
  }, [token]);

  useEffect(() => {
    if (subscriptionStatus !== undefined) {
      setCurrentSubscriptionStatus(subscriptionStatus);
    }
  }, [subscriptionStatus]);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    if (userData?.subscription_status && !currentSubscriptionStatus) {
      setCurrentSubscriptionStatus(userData.subscription_status);
    }
  }, []);

  const handleUnsubscribe = async () => {
    setLoading(true);
    setLoadingMessage("Canceling subscription...");

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/subscriptions`,
        config
      );

      if (response.data.user) {
        // Update sessionStorage and Redux with new user data
        sessionStorage.setItem('userData', JSON.stringify(response.data.user));
        dispatch(setUser(response.data.user));
      }

      // Update subscription state
      dispatch(setSubscriptionStatus(response.data.subscription.status));
      dispatch(setSubscriptionEndDate(response.data.subscription.endDate));
      dispatch(setCancelAtPeriodEnd(response.data.subscription.cancelAtPeriodEnd));

      setSuccessMessage("Your subscription has been cancelled.");
      setCurrentSubscriptionStatus(response.data.subscription.status);

      // No need to reload the page
      // window.location.reload();
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Failed to cancel subscription');
    } finally {
      setLoading(false);
    }
  };

  const handleRenew = async () => {
    setLoading(true);
    setLoadingMessage("Reactivating subscription...");

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/renew_subscription`,
        {},
        config
      );

      if (response.data.user) {
        // Update sessionStorage and Redux with new user data
        sessionStorage.setItem('userData', JSON.stringify(response.data.user));
        dispatch(setUser(response.data.user));
      }

      // Update subscription state
      dispatch(setSubscriptionStatus(response.data.subscription.status));
      dispatch(setSubscriptionEndDate(response.data.subscription.endDate));
      dispatch(setCancelAtPeriodEnd(response.data.subscription.cancelAtPeriodEnd));

      setSuccessMessage("Your subscription has been reactivated.");
      setCurrentSubscriptionStatus('active');

      // No need to reload the page
      // window.location.reload();
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Failed to reactivate subscription');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelTrial = async () => {
    setLoading(true);
    setLoadingMessage("Canceling trial...");

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cancel_trial`,
        {},
        config
      );

      if (response.data.user) {
        // Update sessionStorage and Redux with new user data
        sessionStorage.setItem('userData', JSON.stringify(response.data.user));
        dispatch(setUser(response.data.user));
      }

      // Update subscription state
      dispatch(setSubscriptionStatus(response.data.subscription.status));
      dispatch(setSubscriptionEndDate(response.data.subscription.endDate));
      dispatch(setCancelAtPeriodEnd(response.data.subscription.cancelAtPeriodEnd));

      setSuccessMessage("Your trial has been cancelled.");
      setCurrentSubscriptionStatus('canceled');

      // No need to reload the page
      // window.location.reload();
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Failed to cancel trial');
    } finally {
      setLoading(false);
    }
  };

  const fetchPriceInfo = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/subscriptions/price`,
        config
      );

      setPriceInfo(response.data);
    } catch (error) {
      setErrorMessage('Unable to fetch subscription details. Please try again later.');
    }
  };

  const formatPrice = (price, currency) => {
    if (!price || !currency) return '';

    try {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency.toUpperCase(),
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(price / 100);
    } catch (error) {
      console.error('Error formatting price:', error);
      return `${price / 100} ${currency.toUpperCase()}`;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setLoadingMessage(`Processing your subscription...`);
    setErrorMessage('');

    if (!stripe || !elements) {
      setErrorMessage('Payment system not initialized. Please try again.');
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        throw new Error(error.message);
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create_subscription`,
        { payment_method_id: paymentMethod.id },
        config
      );

      // Update user data in sessionStorage and Redux before reload
      if (response.data.user) {
        const userData = response.data.user;
        sessionStorage.setItem('userData', JSON.stringify(userData));

        // Update Redux store
        dispatch(setUser(userData));  // Make sure you've imported setUser
        dispatch(setSubscriptionStatus(response.data.subscription.status));
        dispatch(setSubscriptionEndDate(response.data.subscription.endDate));
        dispatch(setCancelAtPeriodEnd(response.data.subscription.cancelAtPeriodEnd));

        // Update session token if it's in the response
        if (response.data.jwt) {
          sessionStorage.removeItem('token');
          sessionStorage.setItem('token', response.data.jwt);
        }
      }

      setSuccessMessage(
        currentSubscriptionStatus === 'trialing'
          ? "Your free trial has started!"
          : "Thank you for subscribing to Flickswipe Premium!"
      );

      // Set the correct status based on the response
      setCurrentSubscriptionStatus(response.data.subscription.status);
      setShowCardSection(false);

    } catch (error) {
      console.error('Subscription error:', error);
      setErrorMessage(
        error.response?.data?.error ||
        error.message ||
        'Failed to process subscription. Please try again.'
      );
      cardElement?.clear();
    } finally {
      setLoading(false);
    }
  };

  const renderPriceInfo = () => {
    if (!priceInfo) return 'Loading subscription details...';
    if (!priceInfo.price || !priceInfo.currency) return 'Price information unavailable';

    return `Premium Subscription: ${formatPrice(priceInfo.price, priceInfo.currency)}`;
  };

  const renderSubscriptionDetails = () => {
    if (!subscriptionEndDate) return null;

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    const messages = {
      trialing: `Your trial ends on ${formatDate(subscriptionEndDate)}. After that, you'll be billed ${priceInfo ? formatPrice(priceInfo.price, priceInfo.currency) : 'Loading...'}.`,
      active: cancelAtPeriodEnd
        ? `Your subscription is cancelled and will end on ${formatDate(subscriptionEndDate)}.`
        : `Your subscription will renew on ${formatDate(subscriptionEndDate)}.`,
      canceled: `Your subscription has ended.`
    };

    return (
      <div className="subscription-details">
        <p>{messages[currentSubscriptionStatus] || 'Subscription status unknown'}</p>
        {currentSubscriptionStatus === 'active' && !cancelAtPeriodEnd && (
          <p className="billing-info">
            You are being billed {priceInfo ? formatPrice(priceInfo.price, priceInfo.currency) : 'Loading...'}.
          </p>
        )}
      </div>
    );
  };

  // Card Element styling
  const cardOptions = {
    style: {
      base: {
        iconColor: '#ff7c62',
        color: '#ff7c62',
        fontWeight: '900',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#ff7c62',
        },
      },
      invalid: {
        iconColor: 'red',
        color: 'red',
      },
    },
  };

  return (
    <div className="stripe-subscription">
      {showSalesPitch ? (
        <StripeSalesPitch
          handleSubmit={handleSubmit}
          handleCancelTrial={handleCancelTrial}
          handleUnsubscribe={handleUnsubscribe}
          handleRenew={handleRenew}
          setShowCardSection={setShowCardSection}
          stripe={stripe}
          loading={loading}
          cardOptions={cardOptions}
          priceInfo={priceInfo}
          loadingMessage={loadingMessage}
          successMessage={successMessage}
          errorMessage={errorMessage}
          subscriptionStatus={subscriptionStatus}
          subscriptionEndDate={subscriptionEndDate}
          cancelAtPeriodEnd={cancelAtPeriodEnd}
        />
      ) : (
        <>
          <div className='subInfo'>
            <h2>Subscription Details</h2>
            <p className="price-info">
              {priceInfo ? `Premium Subscription: ${renderPriceInfo()}` : 'Loading subscription details...'}
            </p>
            {renderSubscriptionDetails()}

            {(!currentSubscriptionStatus || currentSubscriptionStatus === 'canceled') && (
              <button
                className="minimalSub-btn"
                onClick={() => setShowCardSection(true)}
              >
                Start 7 Day Free Trial
              </button>
            )}

            {currentSubscriptionStatus === 'active' && !cancelAtPeriodEnd && (
              <button
                className="minimalSub-btn"
                onClick={handleUnsubscribe}
              >
                Cancel Subscription
              </button>
            )}

            {currentSubscriptionStatus === 'active' && cancelAtPeriodEnd && (
              <button
                className="minimalSub-btn"
                onClick={handleRenew}
              >
                Reactivate Subscription
              </button>
            )}

            {currentSubscriptionStatus === 'trialing' && (
              <button
                className="minimalSub-btn"
                onClick={handleCancelTrial}
              >
                Cancel Trial
              </button>
            )}
          </div>

          {showCardSection && (
            <form onSubmit={handleSubmit} className="card-form">
              <CardElement options={cardOptions} />
              <button
                type="submit"
                disabled={!stripe || loading}
                className="subscription-button"
              >
                {loading ? 'Processing...' : 'Subscribe Now'}
              </button>
            </form>
          )}

          {loading && (
            <div className="process-loader">
              <ClipLoader size={35} color="#ff7c62" />
              <p>{loadingMessage}</p>
            </div>
          )}

          {successMessage && (
            <div className="success-message">
              <p>{successMessage}</p>
            </div>
          )}

          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function WrappedStripeSubscription({ showSalesPitch }) {
  return (
    <Elements stripe={stripePromise} options={{ locale: 'auto' }}>
      <StripeSubscription showSalesPitch={showSalesPitch} />
    </Elements>
  );
}

export default WrappedStripeSubscription;
