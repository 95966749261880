import { createSlice } from '@reduxjs/toolkit';

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    status: JSON.parse(sessionStorage.getItem('userData'))?.subscription_status || null,
    endDate: JSON.parse(sessionStorage.getItem('userData'))?.subscription_end_date || null,
    cancelAtPeriodEnd: JSON.parse(sessionStorage.getItem('userData'))?.cancel_at_period_end || false,
  },
  reducers: {
    setSubscriptionStatus: (state, action) => {
      state.status = action.payload;
    },
    setSubscriptionEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setCancelAtPeriodEnd: (state, action) => {
      state.cancelAtPeriodEnd = action.payload;
    },
    clearSubscriptionState: (state) => {
      state.status = null;
      state.endDate = null;
      state.cancelAtPeriodEnd = false;
    },
  },
});

export const {
  setSubscriptionStatus,
  setSubscriptionEndDate,
  setCancelAtPeriodEnd,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
