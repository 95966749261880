import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux/slices/userSlice';
import { setToken } from '../../redux/slices/tokenSlice';
import { setLoggedIn } from '../../redux/slices/authSlice';
import { setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd } from '../../redux/slices/subscriptionSlice';
import PlexUpdateDetails from "./PlexUpdateDetails";

import plexLogo from '../../assets/images/plex-logo.png';
import '../../assets/PlexLoginComponent.css';

const PLEX_AUTH_URL = 'https://app.plex.tv/auth#';

function PlexLoginComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pinId, setPinId] = useState(null);
  const [isIncompleteRegistration, setIncompleteRegistration] = useState(false);
  const [plexUserId, setPlexUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authenticationProcessed, setAuthenticationProcessed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailTaken, setEmailTaken] = useState(false);
  const [plexUserEmail, setPlexUserEmail] = useState("");

  const loggedIn = useSelector((state) => state.auth.loggedIn);

  // Generate a unique identifier that remains consistent for the session
  useEffect(() => {
    if (!localStorage.getItem('plexClientId')) {
      const uniqueId = 'flickswipe-' + Math.random().toString(36).substr(2, 9);
      localStorage.setItem('plexClientId', uniqueId);
    }
  }, []);

  const getPlexHeaders = () => ({
    'Accept': 'application/json',
    'X-Plex-Client-Identifier': localStorage.getItem('plexClientId'),
    'X-Plex-Product': 'Flickswipe',
    'X-Plex-Version': '1.0.0',
    'X-Plex-Device': 'Web',
    'X-Plex-Device-Name': 'Browser',
    'X-Plex-Platform': 'Web',
    'X-Plex-Platform-Version': '1.0.0',
    'X-Plex-Provides': 'controller',
    'X-Plex-Language': 'en'
  });

  const getPlexPin = async () => {
    try {
      const response = await fetch('https://plex.tv/api/v2/pins?strong=true', {
        method: 'POST',
        headers: getPlexHeaders()
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to get Plex PIN');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error getting Plex PIN:', error);
      throw error;
    }
  };

  const handleLogin = async () => {
    try {
      const pinData = await getPlexPin();
      setPinId(pinData.id);
      localStorage.setItem('pinId', pinData.id);

      const params = new URLSearchParams({
        'clientID': localStorage.getItem('plexClientId'),
        'code': pinData.code,
        'context[device][product]': 'Flickswipe',
        'context[device][version]': '1.0.0',
        'context[device][platform]': 'Web',
        'context[device][device]': 'Browser',
        'forwardUrl': `${process.env.REACT_APP_PLEX_REDIRECT_URL}/process-plex-login`
      });

      window.location.href = `${PLEX_AUTH_URL}?${params.toString()}`;
    } catch (error) {
      console.error("Error initiating Plex login:", error);
      setErrorMessage("Failed to initialize Plex login. Please try again.");
    }
  };

  const verifyPlexPin = async (pinId) => {
    const response = await fetch(`https://plex.tv/api/v2/pins/${pinId}`, {
        method: 'GET',
        headers: getPlexHeaders()
    });

    if (!response.ok) {
        throw new Error('Failed to verify PIN');
    }

    const data = await response.json();
    return data.authToken;
  };

  const handlePlexAuthenticated = async () => {
    setLoading(true);
    try {
      const storedPinId = localStorage.getItem('pinId');

      if (!storedPinId) {
        throw new Error('Missing authentication data');
      }

      const authToken = await verifyPlexPin(storedPinId);

      if (!authToken) {
        throw new Error('Failed to get auth token from Plex');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/plex`, {
        method: 'POST',
        headers: {
          ...getPlexHeaders(),
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          pin_id: storedPinId,
          client_id: localStorage.getItem('plexClientId')
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Authentication failed');
      }

      const data = await response.json();

      setPlexUserEmail(data.email);
      localStorage.removeItem('pinId');

      // Handle the response
      if (!data.status) {
        throw new Error('Invalid server response');
      }

      switch (data.status) {
        case 'incomplete_registration':
          setIncompleteRegistration(true);
          setPlexUserId(data.plex_id);
          setPlexUserEmail(data.email);
          break;

        case 'plex_not_linked':
          setEmailTaken(true);
          setPlexUserId(data.plex_id);
          setPlexUserEmail(data.email);
          break;

        case 'success':
          if (data.user) {
            dispatch(setUser(data.user));
            dispatch(setToken(data.jwt));
            dispatch(setLoggedIn(true));
            if (data.user.subscription_status) {
              dispatch(setSubscriptionStatus(data.user.subscription_status));
            }
            if (data.user.subscription_end_date) {
              dispatch(setSubscriptionEndDate(data.user.subscription_end_date));
            }
            if (data.user.cancel_at_period_end !== undefined) {
              dispatch(setCancelAtPeriodEnd(data.user.cancel_at_period_end));
            }

            sessionStorage.setItem('userData', JSON.stringify(data.user));
            sessionStorage.setItem('token', data.jwt);
            if (data.refresh_token) {
              sessionStorage.setItem('refresh_token', data.refresh_token);
            }
          }
          setAuthenticationProcessed(true);
          break;

        default:
          throw new Error(`Unknown authentication status: ${data.status}`);
      }
    } catch (error) {
      setErrorMessage(error.message || "Authentication failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const shouldAuthenticate = !loggedIn &&
    window.location.href.includes('/process-plex-login') &&
    localStorage.getItem('pinId');

  useEffect(() => {
    let isMounted = true;

    const performAuthentication = async () => {
      if (shouldAuthenticate && isMounted) {
        await handlePlexAuthenticated();
        setAuthenticationProcessed(true);
      }
    };

    performAuthentication();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isIncompleteRegistration || emailTaken) {
    return (
      <PlexUpdateDetails
        plexUserEmail={plexUserEmail}
        emailTaken={emailTaken}
        onDetailsUpdated={() => {
          setIncompleteRegistration(false);
          setEmailTaken(false);
          navigate("/");
        }}
      />
    );
  }

  return (
    <div>
      {!authenticationProcessed && (
        <button className="plex-login-btn" onClick={handleLogin}>
          <img src={plexLogo} alt="Plex Logo" className="plex-logo" />
          Login with Plex
        </button>
      )}
      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}

export default PlexLoginComponent;
